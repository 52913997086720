import React, { useCallback, useEffect } from 'react';
import CommonPage from '../CommonUtils/CommonPage';
import {pageType} from '../../APIurls';



const SeatingDetail = () => {
    return(
        <>
       <CommonPage
        title = "Employee Seating Details"
        subtitle = "Employee seating details"
        type = {pageType.SEATING_DEATLS}
        hasNotifyButton={false}
        />
       </>
    );
}

export default SeatingDetail;