import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Location from '../CommonUtils/LocationRenderer/Location';
import Title from '../CommonUtils/title';
import { FormControl, InputLabel, MenuItem, Select, TextField, Tooltip,Tab,Tabs } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Send,FormatClear } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CustomizedSnackbar from '../CommonUtils/snackbar';
import { apiURL } from '../../APIurls';
import { FetchWithTimeout } from '../CommonUtils/fetchWithTimeout';
import { DefaultLocation } from '../../App';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    minWidth: {
        minWidth: "5o%",
    },
    iconRoot: {
        padding: "10px 10px "
    },
    sendButton: {
        margin: theme.spacing(0),
        padding: '',
    },
    selectEmpty: {
        marginTop: theme.spacing(0),
        minWidth: 120
    },
    alighRow: {
        display: "flex",
        alignItems: "center",

    },
    margin: {

    },
    sendDivArea: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    textareaAutosize: {

    },
    fixedHeight: {
        height: 460,
    },
    fixedTextWidth: {
        maxWidth:"60%",
    }
    

}));

interface IBatch {
    id: number,
    timeslot: string
}
interface IResult {
    status: string,
    message: string
}
export default function SendNotification() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    let emptybatchArray: IBatch[] = [];
    let emptyResult: IResult = {
        status: "",
        message: "",
    };

    const [value, setValue] = React.useState(0);
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [locationid, setLocationid] = React.useState('');
    const [regionid, setRegionid] = React.useState('');
    const [batchId, setBatchId] = React.useState(0);
    const [batchItems, setBatchItems] = React.useState(emptybatchArray);
    const [result, setResult] = React.useState(emptyResult);
    const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleTitleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTitle(event.target.value as string);     
        console.log("title", title);
    };
    const handleDescriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDescription(event.target.value as string);
        console.log("desp", description);
    };
    const setRegionidFromDropDown = (id: string) => {
        setRegionid(id);
    }
    const setLocationidFromDropDown = (id: string) => {
        setLocationid(id);
    }
    const handleBatchgChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBatchId(event.target.value as number); 
        console.log("batch", batchId);
    };
    const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }
    React.useEffect(() => {
        if (locationid != "") {
            const getBatch = async () => {
                const reqBody = {
                    locationid: locationid
                }
                try {
                    //const url = 'https://ingramrtw.azurewebsites.net/getbatch';
                    //const url = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/getbatch';
                    const response = await FetchWithTimeout(apiURL.GET_BATCH, {
                        method: 'POST',
                        body: JSON.stringify(reqBody),
                        headers: {
                            'Accept': 'application/json',
                            "Access-Control-Allow-Origin": "*",
                            'Content-Type': "application/json; charset=utf-8",
                            "Authorization": `Bearer ${defaultLocation.accessToken}`, 
                        },
                        mode: 'cors',
                    })
                    return response.json();
                } catch (error) {
                    error.name === 'AbortError' ?
                        setResult({
                            status: "Failed",
                            message: "Your request has timed out. Try again in sometime."
                        })
                        : setResult({
                            status: "Failed",
                            message: "Oops!! Something went wrong."
                        });
                    setSnackbarOpen(true);
                }
            }
            getBatch().then(data => {
                console.log("batch data", data);
                if (data.error) {
                    console.log(data.error);
                } else {

                    let res: IBatch[] = [];
                    res = data;
                    if (res) {
                        let batchData: IBatch[] = [];
                        res.forEach(p => {
                            batchData.push({
                                id: p.id,
                                timeslot: p.timeslot
                            });
                        });
                        setBatchItems(batchData);
                    }
                }
            })
        }
    }, [locationid]);
    
    const defaultLocation = useContext(DefaultLocation);

    const handleSendNotification = (type: string) => {
        const reqBody = {
            type: type,
            announcementtitle: title,
            announcementmessage: description,
            location: locationid,
            batch: batchId
        }
        
        const sendNotification = async () => {
            try {
                //const url ='https://ingramrtw.azurewebsites.net/sendannouncements';
                //const url = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/sendannouncements';
                const response = await FetchWithTimeout(apiURL.SEND_ANNOUNCEMENTS, {
                    method: 'POST',
                    body: JSON.stringify(reqBody),
                    headers: {
                        'Accept': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': "application/json; charset=utf-8",
                        "Authorization": `Bearer ${defaultLocation.accessToken}`, 
                    },
                    mode: 'cors',
                })
                return response.json();
            } catch (error) {
                error.name === 'AbortError' ?
                    setResult({
                        status: "Failed",
                        message: "Your request has timed out. Try again in sometime."
                    })
                    : setResult({
                        status: "Failed",
                        message: "Oops!! Something went wrong."
                    });
                setSnackbarOpen(true);
            }
        }
        sendNotification().then(data => {
            console.log("data", data);
            if (data.StatusDescription == "Success" || data.StatusDescription == "success") {
                let res: IResult = {
                    message: "Notification has been sent",
                    status: "Success"
                }
                setResult(res);
                setSnackbarOpen(true);
                setTitle("");
                setDescription("");
            } else {
                let res: IResult = {
                    message: "Oops!! Something went wrong.",
                    status: data.StatusDescription
                }
                setResult(res);
                setSnackbarOpen(true);
            }
        })

    }

    const clearInput= () => {
        setTitle("");
        setDescription("");
    }


    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Title variant="h4"> 
                    Create Notification
                </Title>
                <div className={clsx(classes.root, classes.fixedHeight,)}>
                    <Paper square>
                        <Tabs value={value} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
                                <Tab label="All" {...a11yProps(0)} />
                                <Tab label="By Location" {...a11yProps(1)} />
                                <Tab label="By Batch" {...a11yProps(2)} />
                        </Tabs>
                    </Paper>
                    <TabPanel value={value} index={0}  >
                        <div className={ classes.fixedTextWidth} >
                            <FormControl fullWidth required >
                                <Title variant="h6">Title</Title>
                                <TextField
                                    onChange={handleTitleChange}
                                    variant="outlined"
                                    value={title?title:"" }
                                />
                                <Title variant="h6">Details</Title>
                                <TextField
                                    multiline
                                    rows={4}                                
                                    onChange={handleDescriptionChange}
                                    variant="outlined"
                                    value={description ? description : ""}
                                />                           
                            </FormControl>
                        </div>
                        <br />
                        <div className={classes.sendDivArea}>
                            <Tooltip title={title == "" ? "Title can't be empty" :
                                    (description == "" ? "Details can't be empty" : "")} arrow>
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={title == "" || description == ""}
                                        className={classes.sendButton}
                                        startIcon={<Send />}
                                        onClick={() => { handleSendNotification("1") }}>
                                        Send
                                    </Button>
                                </div>
                            </Tooltip>
                            <div style={{paddingLeft:"15px"}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.sendButton}
                                    startIcon={<FormatClear />}
                                    onClick={() => { clearInput() }}>
                                    clear
                                </Button>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Location setLocationid={setLocationidFromDropDown} setRegionid={setRegionidFromDropDown} />
                        <div className={classes.fixedTextWidth} >
                            <FormControl fullWidth className={classes.margin}>
                                <Title variant="h6">Title</Title>
                                <TextField
                                    value={title ? title : ""}
                                    onChange={handleTitleChange}
                                    variant="outlined"
                                />
                                <Title variant="h6">Details</Title>
                                <TextField
                                    multiline
                                    rows={4}
                                    onChange={handleDescriptionChange}
                                    value={description ? description : ""}
                                    variant="outlined"
                                />
                            </FormControl>
                        </div>
                        <br />
                        <div className={classes.sendDivArea}>
                            <Tooltip title={locationid == "" ? "Please select a region and location" :
                                    (title == "" ? "Title can't be empty" :
                                    (description == "" ? "Details can't be empty" : ""))} arrow>
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={title == "" || description == ""||locationid==""}
                                        className={classes.sendButton}
                                        startIcon={<Send />}
                                        onClick={() => { handleSendNotification("2") }}>
                                        Send
                                    </Button>
                                </div>
                            </Tooltip>
                            <div style={{ paddingLeft: "15px" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.sendButton}
                                    startIcon={<FormatClear />}
                                    onClick={() => { clearInput() }}>
                                    clear
                                </Button>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <div className={classes.alighRow}>
                            <Location setLocationid={setLocationidFromDropDown} setRegionid={setRegionidFromDropDown} />
                            <div>
                                <InputLabel id="Select-Batch">Batch</InputLabel>
                                <Select
                                    labelId="Select-Batch"
                                    id="selectBatch"
                                        value={batchId?batchId:0}
                                        onChange={handleBatchgChange}
                                    className={classes.selectEmpty}
                                >
                                    {(batchItems).map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.timeslot}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className={classes.fixedTextWidth} >
                            <FormControl fullWidth className={classes.margin}>
                                <Title variant="h6">Title</Title>
                                <TextField
                                    onChange={handleTitleChange}
                                    value={title ? title : ""}
                                    variant="outlined"
                                />
                                <Title variant="h6">Details</Title>
                                <TextField
                                    multiline
                                    rows={4}
                                    onChange={handleDescriptionChange}
                                    value={description ? description : ""}
                                    variant="outlined"
                                />
                            </FormControl>
                            </div>
                       <br />
                        <div className={classes.sendDivArea}>
                            <Tooltip title={locationid == "" ? "Please select a region and location" :
                                (batchId == 0 ? "Please select a batch" :
                                    (title == "" ? "Title can't be empty" :
                                        (description == "" ? "Details can't be empty" : "")))} arrow>
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={title == "" || description == "" || locationid == ""||batchId==0}
                                        className={classes.sendButton}
                                        startIcon={<Send />}
                                        onClick={() => { handleSendNotification("3") }}>
                                        Send
                                    </Button>
                                </div>
                            </Tooltip>
                            <div style={{ paddingLeft: "15px" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.sendButton}
                                    startIcon={<FormatClear />}
                                    onClick={() => { clearInput() }}>
                                    clear
                                    </Button>
                            </div>
                        </div>
                    </TabPanel>
                    <CustomizedSnackbar status={result.status} message={result.message} displaySnackbar={isSnackbarOpen} handleClose={handleSnackbarClose} />
                </div>
            </Grid>
        </Grid>
    );
}



interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

