import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Chart from './chart';
import Location from '../CommonUtils/LocationRenderer/Location';
import Title from '../CommonUtils/title';
import { FormControl, InputLabel, MenuItem, NativeSelect, Tooltip } from '@material-ui/core';
import LiveUpdates from './liveUpdates';
import { apiURL } from '../../APIurls';
import { FetchWithTimeout } from '../CommonUtils/fetchWithTimeout';
import CustomizedSnackbar from '../CommonUtils/snackbar';
import { Refresh } from '@material-ui/icons';
import { DefaultLocation } from '../../App';


const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: '100%',
    },
    chartDiv: {
        minHeight: '400px',
        minWidth: '300px'
    },
    spacing2: {
        margin: theme.spacing(2),
    },
    Togglebutton: {
        color: 'rgb(0 0 0)',
        background: '#3f71c0',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150
    },

}));

interface IIncomingGraphData {
    weekid: number;
    monthid: number;
    dayid: Date;
    planned: number;
    actuals: number;
    adhoc: number;
}
interface IGraphData {
    weekid: string;
    monthid: number;
    dayid: string;
    Planned: number;
    Actuals: number;
    Adhoc: number;
}
interface IResult {
    status: string,
    message: string
}
export default function Dashboard() {
    let emptyGraphDataArray: IGraphData[] = [];
    let incomingGraphData: IIncomingGraphData[] = [];
    let emptyResult: IResult = {
        status: "",
        message: "",
    };

    const classes = useStyles();
    const [locationid, setLocationid] = React.useState('');
    const [regionid, setRegionid] = React.useState('');
    const [graphData, setGraphData] = React.useState(emptyGraphDataArray);
    const [type, setType] = React.useState('1');
    const [reload, setReload] = React.useState('');

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const defaultLocation = useContext(DefaultLocation);

    const [result, setResult] = React.useState(emptyResult);
    const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }
    const getChartData = async () => {
        let todaysDate: string = "" + new Date().getFullYear() + "/" + (new Date().getUTCMonth() + 1).toString().padStart(2, "0") + "/" + new Date().getDate().toString().padStart(2, "0");
        const reqBody = {
            locationid: locationid,
            date: todaysDate,
            type: type,
        };
        try {
            const response = await FetchWithTimeout(apiURL.Get_GRAPHDATA, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${defaultLocation.accessToken}`, 
                },
                body: JSON.stringify(reqBody),
                mode: 'cors',
            })
            return response.json();
        } catch (error) {
            error.name === 'AbortError' ?
                setResult({
                    status: "Failed",
                    message: "Your request has timed out. Try again in sometime."
                })
                : setResult({
                    status: "Failed",
                    message: "Oops!! Something went wrong."
                });
            setSnackbarOpen(true);
        }
    }
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setType(event.target.value as string);
    }

    React.useEffect(() => {

        if (locationid !== '') {
            getChartData()
                .then(data => {
                    incomingGraphData = data;
                    if (incomingGraphData) {
                        const sortedincomingGraphData = incomingGraphData.sort((a, b) => { return new Date(b.dayid).getTime() > new Date(a.dayid).getTime() ? -1 : 1; });
                        let graphData: IGraphData[] = [];
                        sortedincomingGraphData.forEach(p => {
                            let x = new Date(p.dayid);
                            graphData.push({
                                weekid: "week " + p.weekid.toString(10),
                                dayid: x.toDateString(),
                                monthid: p.monthid,
                                Planned: p.planned,
                                Actuals: p.actuals,
                                Adhoc: p.adhoc,

                            });

                        });
                        setReload('no');
                        setGraphData(graphData);
                        //setGraphData(
                        //    [{ monthid: 3, weekid: "week 14", dayid: "Mon Apr 05 2021", Planned: 170, Actuals: 150, Adhoc: 10 },
                        //    { monthid: 3, weekid: "week 14", dayid: "Tue Apr 06 2021", Planned: 160, Actuals: 140, Adhoc: 19 },
                        //    { monthid: 3, weekid: "week 14", dayid: "Wed Apr 07 2021", Planned: 165, Actuals: 155, Adhoc: 17 },
                        //    { monthid: 3, weekid: "week 14", dayid: "Thu Apr 08 2021", Planned: 245, Actuals: 230, Adhoc: 15 },
                        //    { monthid: 3, weekid: "week 14", dayid: "Fri Apr 09 2021", Planned: 170, Actuals: 160, Adhoc: 12 }]
                        //);
                    }
                })
        }


    }, [type, locationid, reload])
    const setRegionidFromDropDown = (id: string) => {
        setRegionid(id);
    }
    const setLocationidFromDropDown = (id: string) => {
        setLocationid(id);
    }
    const refresh = (id: string) => {
        setReload(id);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Paper className={fixedHeightPaper}>
                    <Location setLocationid={setLocationidFromDropDown} setRegionid={setRegionidFromDropDown} /><br />
                    {<LiveUpdates locationid={locationid} setReload={refresh} />}
                    <br /><br />
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={3}
                    >
                        <div className={classes.spacing2}>
                            <Title variant="h5">Floor Occupancy</Title>
                        </div>
                        <div className={classes.spacing2}>
                            <FormControl className={classes.formControl}>
                                <NativeSelect
                                    defaultValue={'1'}
                                    onChange={handleChange}
                                >
                                    <option value={'1'}>Week</option>
                                    <option value={'2'}>Month</option>
                                </NativeSelect>
                            </FormControl>
                        </div>
                    </Grid>
                    {(locationid !== '' && type !== '') ?
                        <div className={classes.chartDiv}> <Chart data={graphData} type={type} /></div>
                        : <div className={classes.spacing2}> <Title variant="subtitle1" color="error">please select location</Title></div>
                    }
                </Paper>
                <CustomizedSnackbar status={result.status} message={result.message} displaySnackbar={isSnackbarOpen} handleClose={handleSnackbarClose} />
            </Grid>
        </Grid>
    );
}