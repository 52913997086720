import React, { useEffect } from "react";
import {
  AccountInfo,
  InteractionRequiredAuthError,
  PublicClientApplication,
  SilentRequest,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Button } from "@material-ui/core";
import "./App.css";
import { loginRequest, msalConfig } from "./azure/authConfig";
import LandingPage from "./pages/LandingPage";
import Login from "./pages/LoginPage";
import Error from "./pages/Error";
import Title from "./components/CommonUtils/title";
import CustomizedSnackbar from "./components/CommonUtils/snackbar";
import { IDefaultLocation } from "./components/CommonUtils/LocationRenderer/Location";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { apiURL } from "./APIurls";
import { FetchWithTimeout } from "./components/CommonUtils/fetchWithTimeout";

let LocationData: IDefaultLocation = {
  regionId: "",
  locationId: "",
  accessToken: "",
};
interface IResult {
  status: string;
  message: string;
}
export const DefaultLocation = React.createContext(LocationData);

const useStyles = makeStyles((theme) => ({
  alignScreenCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  },
  alignCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    background: "#fdfff4",
  },
}));
function App() {
  let emptyResult: IResult = {
    status: "",
    message: "",
  };

  const classes = useStyles();
  const [isAuth, setIsAuth] = React.useState("");
  const [isLoading, setIsLoading] = React.useState("");
  const [userName, setuserName] = React.useState("");

  const [result, setResult] = React.useState(emptyResult);
  //const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);

  const msalInstance = new PublicClientApplication(msalConfig);

  //const handleSnackbarClose = (
  // event?: React.SyntheticEvent,
  // reason?: string
  //) => {
  // if (reason === "clickaway") {
  // return;
  // }
  // setSnackbarOpen(false);
  //};

  //const getGraphProfile = async (token: string) => {
  // var headers = new Headers();
  // console.log(token);
  // var bearer = "Bearer " + token;
  // headers.append("Authorization", bearer);
  // var options = {
  // method: "GET",
  // headers: headers,
  // };
  // var graphEndpoint =
  // "https://graph.microsoft.com/v1.0/me?$select=id,displayName,givenName,mail,employeeId";
  // const response = await fetch(graphEndpoint, options);

  // return response.json();
  //};

  const getprofile = async (token?: any) => {
    //const reqBody = {
    // employeeId: empId,
    //};
    //var data = new FormData();
    //data.append("json", JSON.stringify(reqBody));
    //const url = 'https://ingramrtw.azurewebsites.net/getemployeedetails';
    //const url = 'https://apactest.ingrammicro.com/HiveAPI/getemployeedetails';
    const url = apiURL.GET_EMPLOYEEDETAILS;
    try {
      const response = await FetchWithTimeout(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        //body: JSON.stringify(reqBody),
        mode: "cors",
      });

      return response.json();
    } catch (error: any) {
      error && error.name === "AbortError"
        ? setResult({
            status: "Failed",
            message: "Your request has timed out. Try again in sometime.",
          })
        : setResult({
            status: "Failed",
            message: "Oops!! Something went wrong.",
          });
      //setSnackbarOpen(true);
      setIsAuth("Error");
      setIsLoading("loaded");
    }
  };

  const handleLogin = async () => {
    try {
      setIsLoading("true");
      const tokenResponse = await msalInstance.loginPopup(loginRequest);
      const myAccounts: AccountInfo[] = await msalInstance.getAllAccounts();
      const accreq: SilentRequest = {
        account: myAccounts[0],
        //scopes: ["api://ac153d38-1d00-499f-b2e5-d83de473d346/.default"],
        scopes: [`${process.env.REACT_APP_SCOPES!}`], //added scopes in env
      };
      const apiResponse = await msalInstance.acquireTokenSilent(accreq);
      console.log("api response", apiResponse);
      LocationData = {
        regionId: "",
        locationId: "",
        accessToken: apiResponse.accessToken,
      };
      // const graphProfile = await getGraphProfile(tokenResponse.accessToken);
      //const empId = graphProfile.employeeId;
      // let temp = graphProfile.employeeId;
      //console.log(graphProfile);

      //let empid = "";
      //const replace = new RegExp("r'^0+(?!$)'");
      //temp = temp.replace(/^0+/, "");
      //empid = temp;
      //const empId = parseInt(graphProfile.employeeId);

      const profile = await getprofile(apiResponse.accessToken);
      if (profile.Result.length == 0) {
        setResult({
          status: "Failed",
          message: "You do not have enough privileges to login",
        });
        //setSnackbarOpen(true);
        setIsAuth("Error");
        setIsLoading("loaded");
      } else {
        const roleId = profile.Result.roleId;
        const RegionId = profile.Result.regionId;
        const LocationId = profile.Result.location;
        LocationData = {
          regionId: RegionId,
          locationId: LocationId,
          accessToken: apiResponse.accessToken,
        };

        if (
          roleId === 3 ||
          roleId === 4 ||
          roleId === 5 ||
          roleId === 6 ||
          roleId === 7
        ) {
          setIsAuth("1");
          setIsLoading("loaded");
        } else {
          setIsAuth("unauthorized");
          setIsLoading("loaded");
          setResult({
            status: "unauthorized",
            message: "You do not have enough privileges to login",
          });
        }
      }
    } catch (err) {
      console.log(err);
      setResult({
        status: "Failed",
        message: "Oops!! Something went wrong.",
      });
      //setSnackbarOpen(true);
      setIsAuth("Error");
      setIsLoading("loaded");
    }
  };

  const silentSSO = async () => {
    setIsLoading("true");
    //const myAccounts: AccountInfo[] = await msalInstance.getAllAccounts();

    const myAccounts: AccountInfo[] = await msalInstance.getAllAccounts();
    const accreq: SilentRequest = {
      account: myAccounts[0],
      // scopes: ["api://ac153d38-1d00-499f-b2e5-d83de473d346/.default"],
      scopes: [`${process.env.REACT_APP_SCOPES!}`], //added scopes in env
    };
    let silentRequest = {
      scopes: ["openid profile offline_access user.read directory.read.all"],
      loginHint: "",
    };
    if (myAccounts.length > 0) {
      silentRequest = {
        scopes: ["openid profile offline_access user.read directory.read.all"],
        loginHint: myAccounts[0].username,
      };
    } else {
      console.log("no accounts found");
    }

    try {
      //if (userName !== "") {
      // const tokenResponse = await msalInstance.ssoSilent(silentRequest);

      //const graphProfile = await getGraphProfile(tokenResponse.accessToken);
      //let temp = graphProfile.employeeId
      //let empid = '';
      //try {
      // empid = parseInt(temp).toString();
      // console.log("int",empid);
      //}
      //catch {
      // empid = temp;
      // console.log("string", empid);

      //}
      //if (empid == 'NaN') {
      // empid = temp;
      // console.log("string if", empid);

      //}
      //let temp = '1234';
      //let temp = graphProfile.employeeId;
      //console.log(graphProfile);

      //let empid = "";
      ////const replace = new RegExp("r'^0+(?!$)'");
      //temp = temp.replace(/^0+/, "");
      //empid = temp;

      //const containsAlphabets = new RegExp('[A-Za-z]');
      //if (containsAlphabets.test(temp)) {
      // empid = temp;
      // console.log("string", empid);
      //}
      //else {
      // empid = parseInt(temp).toString();
      // console.log("int", empid);
      //}

      //console.log("Final",empid);

      //const empId = parseInt(graphProfile.employeeId);

      const apiResponse = await msalInstance.acquireTokenSilent(accreq);
      //const apiResponse = {accessToken: "aasfvkahsfcvhjcvhkasvcjhavsjhavcshvcshjavscjhavsjhvcha" };

      console.log("api response", apiResponse);
      LocationData = {
        regionId: "",
        locationId: "",
        accessToken: apiResponse.accessToken,
      };

      const profile = await getprofile(apiResponse.accessToken);
      if (profile.Result.length == 0) {
        setResult({
          status: "Failed",
          message: "You do not have enough privileges to login",
        });
        //setSnackbarOpen(true);
        setIsAuth("Error");
        setIsLoading("loaded");
      } else {
        const roleId = profile.Result.roleId;
        const RegionId = profile.Result.regionId;
        const LocationId = profile.Result.location;
        LocationData = {
          regionId: RegionId,
          locationId: LocationId,
          accessToken: apiResponse.accessToken,
        };

        if (
          roleId === 3 ||
          roleId === 4 ||
          roleId === 5 ||
          roleId === 6 ||
          roleId === 7
        ) {
          setIsAuth("1");
          setIsLoading("loaded");
        } else {
          setIsAuth("unauthorized");
          setIsLoading("loaded");
          setResult({
            status: "unauthorized",
            message: "You do not have enough privileges to login",
          });
        }
      }

      setIsLoading("loaded");
      //}
      //else {
      // setIsAuth("Error");
      // setIsLoading("loaded");
      //}
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        const loginResponse = await msalInstance
          .loginPopup(silentRequest)
          .catch((error) => {
            console.log("error", error, loginResponse);
          });
      } else {
        setIsAuth("Error");
        setIsLoading("loaded");
      }
    }
  };

  useEffect(() => {
    silentSSO();
  }, []);
  const TemphandleLogin = async () => {
    const profile = await getprofile(228357);
    console.log("profile", profile);
    setIsAuth("1");
    setIsLoading("loaded");
  };
  return (
    <>
      {
        <MsalProvider instance={msalInstance}>
          <AuthenticatedTemplate>
            {isLoading !== "loaded" ? (
              <div className={classes.alignScreenCenter}>
                <CircularProgress /> <Title variant="h6">loading... </Title>
              </div>
            ) : isAuth === "1" ? (
              <DefaultLocation.Provider value={LocationData}>
                <LandingPage />
              </DefaultLocation.Provider>
            ) : (
              <Error message={result.message} status={result.status} />
              // <Login>
              // <div className={classes.alignCenter}>
              // <Button
              // variant="contained"
              // color="primary"
              // style={{ fontSize: "30px" }}
              // onClick={handleLogin}
              // >
              // Login
              //</Button>
              // <br />
              // <Title variant="h4" color="error">
              // You do not have enough privileges to login
              //</Title>
              // </div>
              // </Login>
            )}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Login>
              <Button
                variant="contained"
                color="primary"
                style={{ fontSize: "30px" }}
                onClick={handleLogin}
              >
                Login
              </Button>
            </Login>
          </UnauthenticatedTemplate>
        </MsalProvider>
      }
      {/* isAuth === "1" ? <DefaultLocation.Provider value={LocationData}><LandingPage /></DefaultLocation.Provider>
:
<Login>
<div className={classes.alignCenter}>
<Button
variant="contained"
color="primary"
style={{ fontSize: '30px' }}
onClick={TemphandleLogin}
>
Login
</Button><br />
<Title variant="h4" color="error">You do not have enough privileges to login</ Title>
</div>
</Login>

<CustomizedSnackbar
status={result.status}
message={result.message}
displaySnackbar={isSnackbarOpen}
handleClose={handleSnackbarClose}
/>
*/}
    </>
  );
}

export default App;
