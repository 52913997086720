import React, { useState } from "react";
import clsx from "clsx";
import {
  Menu,
  HomeRounded,
  GroupAdd,
  CalendarToday,
  MenuOpen,
  EventSeatRounded,
  EventNoteRounded,
  CloudDownload,
  SearchRounded,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Dashboard from "../components/Dashboard/dashboard";
import SendNotification from "../components/SendNotification/sendNotification";
import EmployeeOnboard from "../components/EmployeeOnboard/employeeOnboard";
import EmployeeSchedule from "../components/EmployeeSchedule/employeeSchedule";
import SeatingDetail from "../components/SeatingDetail/SeatingDetail";
import DownloadReport from "../components/DownloadReport/downloadReport";
//import { ReactComponent as Logo } from '../../public/logo/announcement.svg';
import { ReactComponent as Logo } from "../components/CommonUtils/Images/announcement.svg";
import { blue, grey } from "@material-ui/core/colors";
import logo from "../components/CommonUtils/Images/logo.png";
import logoText from "../components/CommonUtils/Images/logoText.png";
import SearchEmployee from "../components/SearchEmployee/searchEmployee";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    minHeight: "60px",
    paddingRight: 10, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
    background: "#fdfff4",
  },
  logoOnDrawer: {
    alignSelf: "flex-start",
  },
  homeButtonOnDrawer: {
    alignSelf: "center",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#fdfff4",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 20,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  inline: {
    display: "inline",
  },
  maxDrawerWidth: {
    maxWidth: `${drawerWidth}px`,
    overflow: "hidden",
  },
  listitems: {
    overflowWrap: "break-word",
    justifyContent: "space-between",
  },
  selected: {
    background: grey[100],
  },
  logo: {
    maxWidth: "35px",
    maxHeight: "35px",
  },
  logoText: {
    maxWidth: "70px",
    maxHeight: "20px",
    paddingLeft: "5px",
    color: "primary",
  },
  logoDiv: {
    display: "flex",
    alignItems: "center",
    background: "#fdfff4",
    padding: "12px 1px",
  },
  iconRoot: {
    minWidth: "40px",
  },
  svgIcon: {
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.7rem",
    transitionProperty: "fill",
    transitionDuration: "200ms",
    transitionDelay: "0ms",
  },
}));

export default function LandingPage() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState(MenuOptions.home);
  const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSearchBtnClicked = () => {
    setIsSearchBtnClicked((prevValue) => !prevValue);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const mainListItems = (
    <div>
      <ListItem
        button
        onClick={() => setCurrentItem(MenuOptions.home)}
        className={currentItem == MenuOptions.home ? classes.selected : ""}
      >
        <ListItemIcon classes={{ root: classes.iconRoot }}>
          <HomeRounded
            color={currentItem == MenuOptions.home ? "primary" : undefined}
          />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>

      <ListItem
        button
        onClick={() => {
          setCurrentItem(MenuOptions.search);
          handleSearchBtnClicked();
        }}
        className={currentItem == MenuOptions.search ? classes.selected : ""}
      >
        <ListItemIcon classes={{ root: classes.iconRoot }}>
          <SearchRounded
            color={currentItem == MenuOptions.search ? "primary" : undefined}
          />
        </ListItemIcon>
        <ListItemText primary="Search Employee" />
      </ListItem>

      <ListItem
        button
        onClick={() => setCurrentItem(MenuOptions.EmployeeOnboarding)}
        className={
          currentItem == MenuOptions.EmployeeOnboarding ? classes.selected : ""
        }
      >
        <ListItemIcon classes={{ root: classes.iconRoot }}>
          <GroupAdd
            color={
              currentItem == MenuOptions.EmployeeOnboarding
                ? "primary"
                : undefined
            }
          />
        </ListItemIcon>
        <ListItemText primary="Onboard Employees" />
      </ListItem>
      <ListItem
        button
        onClick={() => setCurrentItem(MenuOptions.EmployeeSchedule)}
        className={
          currentItem == MenuOptions.EmployeeSchedule ? classes.selected : ""
        }
      >
        <ListItemIcon classes={{ root: classes.iconRoot }}>
          <EventNoteRounded
            color={
              currentItem == MenuOptions.EmployeeSchedule
                ? "primary"
                : undefined
            }
          />
        </ListItemIcon>
        <ListItemText primary="Office Schedule" />
      </ListItem>
      <ListItem
        button
        onClick={() => setCurrentItem(MenuOptions.SeatingDetails)}
        className={
          currentItem == MenuOptions.SeatingDetails ? classes.selected : ""
        }
      >
        <ListItemIcon classes={{ root: classes.iconRoot }}>
          <EventSeatRounded
            color={
              currentItem == MenuOptions.SeatingDetails ? "primary" : undefined
            }
          />
        </ListItemIcon>
        <ListItemText primary="Seating Details" />
      </ListItem>
      <ListItem
        button
        onClick={() => setCurrentItem(MenuOptions.SendNotification)}
        className={
          currentItem == MenuOptions.SendNotification ? classes.selected : ""
        }
      >
        <ListItemIcon classes={{ root: classes.iconRoot }}>
          <Logo
            fill={
              currentItem == MenuOptions.SendNotification
                ? "#3f71c0"
                : "rgba(0, 0, 0, 0.54)"
            }
            className={classes.svgIcon}
          />
          {/*    <svg src={window.location.origin + '/logo/campaign_black_24dp.svg'} className={classes.svgIcon} color={currentItem == MenuOptions.SendNotification ? "primary" : undefined} />*/}
        </ListItemIcon>
        <ListItemText primary="Create Notification" />
      </ListItem>
      <ListItem
        button
        onClick={() => setCurrentItem(MenuOptions.DownloadReport)}
        className={
          currentItem == MenuOptions.DownloadReport ? classes.selected : ""
        }
      >
        <ListItemIcon classes={{ root: classes.iconRoot }}>
          <CloudDownload
            color={
              currentItem == MenuOptions.DownloadReport ? "primary" : undefined
            }
          />
        </ListItemIcon>
        <ListItemText primary="Download Reports" />
      </ListItem>
    </div>
  );
  let view = <Dashboard />;

  switch (currentItem) {
    case MenuOptions.home:
      view = <Dashboard />;
      break;
    case MenuOptions.SendNotification:
      view = <SendNotification />;
      break;
    case MenuOptions.EmployeeOnboarding:
      view = <EmployeeOnboard />;
      break;
    case MenuOptions.EmployeeSchedule:
      view = <EmployeeSchedule />;
      break;
    case MenuOptions.SeatingDetails:
      view = <SeatingDetail />;
      break;
    case MenuOptions.DownloadReport:
      view = <DownloadReport />;
      break;

    case MenuOptions.search:
      view = <SearchEmployee isSearchBtnClicked={isSearchBtnClicked} />;
      break;
    default:
      view = <Dashboard />;
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <Menu color="primary" />
          </IconButton>
          {!open && (
            <div
              className={classes.logoDiv}
              aria-label="open drawer"
              onClick={handleDrawerOpen}
            >
              <img src={logo} className={classes.logo} />
              <img src={logoText} className={classes.logoText} />
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          {open && (
            <div className={classes.logoOnDrawer}>
              <div onClick={handleDrawerClose} className={classes.logoDiv}>
                <img src={logo} className={classes.logo} />
                <img src={logoText} className={classes.logoText} />
              </div>
            </div>
          )}
          <div /*className={classes.homeButtonOnDrawer}*/>
            <IconButton onClick={handleDrawerClose}>
              <MenuOpen color="primary" />
            </IconButton>
          </div>
        </div>
        <Divider />
        <List className={classes.maxDrawerWidth}>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {view}
          <Box pt={4}></Box>
        </Container>
      </main>
    </div>
  );
}

export enum MenuOptions {
  home = "home",
  search = "search",
  SendNotification = "SendNotification",
  EmployeeOnboarding = "employeeOnboarding",
  EmployeeSchedule = "employeeSchedule",
  SeatingDetails = "seatingDetails",
  DownloadReport = "downloadReport",
}
