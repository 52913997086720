import { Formik, Form, useField, ErrorMessage } from "formik";
import * as yup from "yup";
import {
  Button,
  FormControl,
  Input,
  Paper,
  InputLabel,
  Switch,
  Tooltip,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Title from "../CommonUtils/title";
import SelectLocation, {
  IRegion,
  UserInputSelection,
} from "./userInputSelection";
import { useContext, useEffect, useState } from "react";
import { FetchWithTimeout } from "../CommonUtils/fetchWithTimeout";
import { apiURL } from "../../APIurls";
import { IDefaultLocation } from "../CommonUtils/LocationRenderer/Location";
import { DefaultLocation } from "../../App";
import { GetRegionResponse } from "../CommonUtils/LocationRenderer/locationModels";
import { Suggestions } from "./searchEmployee";
import { Autocomplete } from "@material-ui/lab";

interface FormPageProps {
  onHandleSubmitButton: any;
  employee: any;
  getEmployeeDetails: any;
  region: string;
  alias: any;
}

interface UserInputProps {
  label: string;
  type: string;
  name: string;
  disabled?: boolean;
  style?: any;
  placeHolder?: string;
  hover?: string;
  max?: any;
  min?: any;
}

export function UserInput(props: UserInputProps) {
  const { label, style, disabled, placeHolder, hover, max, min, ...rest } =
    props;

  const [field] = useField(rest);

  const useStyle = makeStyles(() => ({
    required: {
      color: "rgba(0, 0, 0, 0.54)",
      paddingLeft: "0.5rem",
    },

    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "center",
    },

    textField: {
      width: "70%",
    },
  }));

  const classes = useStyle();

  return (
    <FormControl variant="standard" className={classes.container}>
      <div>
        <label
          htmlFor={field.name}
          style={
            !style
              ? { color: "rgba(0, 0, 0, 0.54)" }
              : { color: style.colorl ? style.colorl : "rgba(0, 0, 0, 0.54)" }
          }
        >
          {label === "Address" || label === "Phone No." ? label : `${label}*`}
          {/* <span className={classes.required}>
            <ErrorMessage name={field.name} />
          </span> */}
        </label>
      </div>
      <Tooltip title={hover ? hover : ""} placement="top">
        <Input
          id="input-with-icon-textfield"
          placeholder={placeHolder ? placeHolder : ""}
          disabled={disabled}
          style={
            style ? { color: style.colort, width: "95%" } : { width: "70%" }
          }
          {...field}
          {...rest}
          inputProps={{
            maxLength: label === "Address" ? 140 : "",
          }}
        />
      </Tooltip>
    </FormControl>
  );
}

const FormPage = ({
  onHandleSubmitButton,
  employee,
  getEmployeeDetails,
  region,
  alias,
}: FormPageProps) => {
  let locationIdValue: any = null;
  let suggetionList: Suggestions[] = [];
  let inputVal: string = "";
  let mName: string = "";
  let iamAliasFor: any = "";

  const [locationId, setLocationId] = useState(locationIdValue);
  const [hasLeft, setHasLeft] = useState(employee ? employee["hasleft:"] : "");
  const [skipManagerApproval, setSkipManagerApproval] = useState(
    employee?.skipManagerApproval === "0" ||
      employee?.skipManagerApproval === null
      ? false
      : true
  );
  const [suggestions, setSuggestions] = useState(suggetionList);
  const [managerSuggestions, setManagerSuggestions] = useState(suggetionList);
  const [inputValue, setInputValue] = useState(inputVal);
  const [managerName, setManagerName] = useState(employee?.manager);
  const [managerEmpId, setManagerEmpId] = useState("");
  const [managerEmailId, setManagerEmailId] = useState("");
  const [isAliasFor, setIsAliasFor] = useState(iamAliasFor);
  const [isFormSubmitting, setIsFormSubmitting] = useState(true);

  const validate = yup.object({
    empFirstName: yup.string().required(),
    empLastName: yup.string().required(),
    empEmail: yup.string().email().required(),
    empId: yup.string().required(),
    empPhoneNumber: yup.string(),
    empAddress: yup.string().max(140),
    empDesignation: yup.string().required(),
    empDepartment: yup.string().required(),
    empLocation: yup.string().required(),
    empCity: yup.string().required(),
  });

  const useStyle = makeStyles(() => ({
    container: {
      padding: "16px",
    },

    wrapper: {
      display: "grid",
      gridTemplateColumns: "50% 50%",

      margin: "2rem",
    },

    wrapper1: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },

    title: {
      fontSize: "1rem",
      fontWeight: 600,
    },

    button: {
      margin: "1rem",
    },

    btnWrapper: {
      paddingLeft: "1rem",

      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },
    "MuiAutocomplete-endAdornment": {
      display: "none",
    },
  }));

  const classes = useStyle();

  let defaultLocation: IDefaultLocation = {
    regionId: "",
    locationId: "",
  };
  defaultLocation = useContext(DefaultLocation);

  const getLocationId = (value: string) => {
    setLocationId(value);
  };

  const submitFormValues = async (values: any) => {
    await FetchWithTimeout(apiURL.UPDATE_EMPLOYEEDETAILS, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${defaultLocation.accessToken}`,
      },
      body: JSON.stringify(values),
      mode: "cors",
    });

    const response = await FetchWithTimeout(apiURL.GET_EMPPROFILE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${defaultLocation.accessToken}`,
      },
      body: JSON.stringify({
        employeeId: employee?.employeeId,
      }),
      mode: "cors",
    });

    const data = await response.json();

    getEmployeeDetails(data.Result);
  };

  const getSuggestionData = async (value: string) => {
    const response = await FetchWithTimeout(apiURL.GET_SUGGESTIONS, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${defaultLocation.accessToken}`,
      },
      body: JSON.stringify({
        search: value,
      }),
      mode: "cors",
    });

    const data = await response.json();

    return data;
  };

  const managerClearBtn = document.getElementsByClassName(
    "MuiAutocomplete-endAdornment"
  )[0];
  managerClearBtn?.addEventListener("click", () => {
    setManagerName("");
    setManagerEmailId("");
    setManagerEmpId("");
  });
  const aliasClearBtn = document.getElementsByClassName(
    "MuiAutocomplete-endAdornment"
  )[1];
  aliasClearBtn?.addEventListener("click", () => {
    setInputValue("");
    setIsAliasFor("");
  });

  const handleInputValueChange = (e: any) => {
    setInputValue(e.target.value);
    if (suggestions[0]?.empid.includes(e.target.value)) {
      setInputValue(e.target.value);
    }
  };

  const handleManagerNameChange = (e: any) => {
    setManagerName(e.target.value);
    if (managerSuggestions[0]?.empid.includes(e.target.value)) {
      setManagerName(e.target.value);
    }
  };

  const handleInputValue = async (e: any) => {
    if (e) {
      const value: string = e.target.innerText?.split(" ")[0];
      if (value) {
        setIsAliasFor(value);
        const inputVal: string = e.target.innerText?.split("-")[1];
        setInputValue(inputVal);
      }

      if (e.key === "Enter") {
        const alias: any = suggestions.filter(
          (item) => item.empid === isAliasFor
        );

        setInputValue(`${alias[0]?.empfirstname} ${alias[0]?.emplastname}`);
      }
    }
  };

  const handleOnClickManagerName = async (e: any) => {
    if (e) {
      const value: string = e.target.innerText?.split(" ")[0];
      if (value) {
        setManagerEmpId(value);

        const manager: Suggestions[] = managerSuggestions.filter(
          (manager) => manager.empid === value
        );

        setManagerEmailId(manager[0].emailid);

        const inputVal: string = e.target.innerText?.split("-")[1];
        setManagerName(inputVal.trim());
      }

      if (e.key === "Enter") {
        const manager: Suggestions[] = managerSuggestions.filter(
          (manager) => manager.empid === managerEmpId
        );

        setManagerName(`${manager[0].empfirstname} ${manager[0].emplastname}`);
      }
    }
  };

  const handleManagerName = async (e: any, option: any) => {
    if (e) {
      setManagerEmailId(option.emailid);
      setManagerEmpId(option.empid);
    }
  };

  useEffect(() => {
    getSuggestionData(inputValue).then((data) => {
      inputValue && data.Result
        ? setSuggestions(data.Result)
        : setSuggestions([]);
    });
  }, [inputValue]);

  useEffect(() => {
    getSuggestionData(managerName).then((data) => {
      managerName && data.Result
        ? setManagerSuggestions(data.Result)
        : setManagerSuggestions([]);
    });
  }, [managerName]);

  useEffect(() => {
    setManagerEmailId(employee?.managerEmailId);
    setManagerEmpId(employee?.managerEmpId);
  }, [employee?.managerEmailId]);

  useEffect(() => {
    if (alias) {
      if (alias[0].empid) {
        setInputValue(
          alias[0].empfirstname
            ? `${alias[0].empfirstname} ${alias[0].emplastname}`
            : ""
        );
        setIsAliasFor(alias[0].empid);
      }
    } else {
      setIsAliasFor("");
      setInputValue("");
    }

    if (!employee?.isaliasfor) {
      setIsAliasFor("");
      setInputValue("");
    }
  }, [alias, employee?.isaliasfor, employee?.employeeId]);

  return (
    <>
      <Title variant="h4">Edit Employee Details</Title>
      <Paper className={classes.container}>
        <Formik
          initialValues={{
            empFirstName: employee?.firstName,
            empLastName: employee?.lastName,
            empEmail: employee?.emailId,
            empId: employee?.employeeId,
            empPhoneNumber: !employee?.contactNo ? "" : employee?.contactNo,
            empAddress: !employee?.fullAddress ? "" : employee?.fullAddress,
            empDesignation: employee?.title,
            empDepartment: employee?.department,
            region: region,
            empLocation: employee?.roleName[0],
            empCity: employee?.city,

            hasLeft: !employee?.hasleft ? "No" : "Yes",
          }}
          validationSchema={validate}
          validateOnChange
          onSubmit={(values, onSubmitProps) => {
            if (!isFormSubmitting) {
              return setIsFormSubmitting(true);
            }
            const newValues = {
              ...values,
              empManager: managerName,
              managerEmpId,
              managerEmailId,
              hasLeft: hasLeft ? "1" : "0",
              isaliasfor: isAliasFor,
              skipmanagerapproval: skipManagerApproval ? "1" : "0",
              empLocation: locationId.filter(
                (item: any) => item.label === values.empLocation
              )[0].value,
            };
            // console.log(newValues);
            submitFormValues(newValues);
            onSubmitProps.resetForm();
            onSubmitProps.setSubmitting(false);
            onHandleSubmitButton();
          }}
        >
          {(formik) => (
            <Form
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setIsFormSubmitting(false);
                }
              }}
            >
              <div className={classes.wrapper}>
                <UserInput
                  label="Employee Id"
                  name="empId"
                  type="text"
                  disabled
                />
                <UserInput label="Email" name="empEmail" type="text" />
              </div>

              <div className={classes.wrapper}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",

                    width: "70%",
                  }}
                >
                  <UserInput
                    label="First Name"
                    name="empFirstName"
                    type="text"
                    style={{ width: "75%" }}
                  />
                  <UserInput
                    label="Last Name"
                    name="empLastName"
                    type="text"
                    style={{ width: "75%" }}
                  />
                </div>
                <UserInput
                  label="Phone No."
                  name="empPhoneNumber"
                  type="text"
                />
              </div>

              <div className={classes.wrapper}>
                <UserInput label="Address" name="empAddress" type="text" />
                <div>
                  <label style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                    Manager Name *
                    {/* {!managerName && (
                      <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>*</span>
                    )} */}
                  </label>
                  <Autocomplete
                    inputValue={managerName ? managerName : ""}
                    onInputChange={handleOnClickManagerName}
                    onHighlightChange={handleManagerName}
                    autoSelect
                    freeSolo
                    style={{ width: "70%" }}
                    size="small"
                    options={managerSuggestions}
                    getOptionLabel={(option) =>
                      `${option.empid} - ${option.empfirstname} ${option.emplastname}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        onChange={handleManagerNameChange}
                      />
                    )}
                  />
                </div>
              </div>

              <div className={classes.wrapper}>
                <UserInput
                  label="Designation"
                  name="empDesignation"
                  type="text"
                />
                <UserInput
                  label="Department"
                  name="empDepartment"
                  type="text"
                />
              </div>

              <div className={classes.wrapper}>
                <SelectLocation
                  label="Location"
                  name1={{ name: "empLocation" }}
                  name2={{ name: "region" }}
                  type="select"
                  getLocationId={getLocationId}
                />
                <UserInput label="City" name="empCity" type="text" />
              </div>

              <div className={classes.wrapper}>
                <div>
                  <label style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                    I am Alias for
                  </label>
                  <Autocomplete
                    inputValue={inputValue ? inputValue : ""}
                    onInputChange={handleInputValue}
                    onHighlightChange={(e: any, option) => {
                      if (e) {
                        setIsAliasFor(option?.empid);
                      }
                    }}
                    autoSelect
                    freeSolo
                    style={{ width: "70%" }}
                    size="small"
                    options={suggestions}
                    getOptionLabel={(option) =>
                      `${option.empid} - ${option.empfirstname} ${option.emplastname}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        onChange={handleInputValueChange}
                      />
                    )}
                  />
                </div>
                <div className={classes.wrapper1}>
                  <div>
                    <label style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                      Skip Manager Approval
                    </label>
                    <Switch
                      checked={skipManagerApproval}
                      onChange={() =>
                        setSkipManagerApproval(!skipManagerApproval)
                      }
                      name="Has Left"
                      color="primary"
                    />
                  </div>
                  <div style={{ marginLeft: "3rem" }}>
                    <label style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                      Has Left
                    </label>
                    <Switch
                      checked={hasLeft}
                      onChange={() => setHasLeft(!hasLeft)}
                      name="Has Left"
                      color="primary"
                    />
                  </div>
                </div>
              </div>

              <div className={classes.btnWrapper}>
                <Button
                  className={classes.button}
                  variant="contained"
                  type="submit"
                  color="primary"
                  size="small"
                  disabled={
                    !formik?.values?.empFirstName ||
                    !formik?.values?.empLastName ||
                    !formik?.values?.empEmail ||
                    !formik?.values?.empId ||
                    !formik?.values?.empCity ||
                    !formik?.values?.empCity ||
                    !formik?.values?.empDepartment ||
                    !formik?.values?.empDesignation ||
                    !managerName
                  }
                >
                  Submit
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  type="button"
                  size="small"
                  onClick={() => {
                    formik.handleReset();
                    onHandleSubmitButton();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
};

export default FormPage;
