import React from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


interface snackbarprops  {
    status: string,
    message: string,
    displaySnackbar: boolean,
    handleClose: (event?: React.SyntheticEvent, reason?: string)=> void
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function CustomizedSnackbar(props: snackbarprops) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
            setOpen(props.displaySnackbar);
		
    }, [props.displaySnackbar]);

    //const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    //    if (reason === 'clickaway') {
    //        return;
    //    }
    //    setOpen(false);
    //};

    return (
        <div className={classes.root}>
            
            <Snackbar open={open} autoHideDuration={6000} onClose={props.handleClose}>
                <Alert onClose={props.handleClose} severity={(props.status ==("Success"||"success"))?"success":"error"}>
                    {props.message}
                </Alert>
            </Snackbar>
        </div>
    );
}
