import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DeleteRounded } from "@material-ui/icons";
import React, { useContext, useEffect } from "react";
import { date } from "yup";
import { apiURL } from "../../APIurls";
import { DefaultLocation } from "../../App";
import { IDefaultLocation } from "../CommonUtils/LocationRenderer/Location";
import { months } from "./adhocVisitTable";

interface Data {
  startDate: string;
  endDate: string;
  timeSlot: string;
  location: string;
  reqId: string;
  action: any;
}

function createData(
  startDate: string,
  endDate: string,
  timeSlot: string,
  location: string,
  reqId: string,
  action: any
): Data {
  return {
    startDate,
    endDate,
    timeSlot,
    location,
    reqId,
    action,
  };
}

// const rows = [createData("2022-03-12", "2022-03-12", "11 am - 6 pm", "KM", "")];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "startDate",
    numeric: false,
    disablePadding: true,
    label: "Start Time",
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "End Time",
  },
  {
    id: "timeSlot",
    numeric: true,
    disablePadding: false,
    label: "Time Slot",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

interface UpcomingVisitsTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function UpcomingVisitsTableHead(props: UpcomingVisitsTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected > 0}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding="none"
            style={{ color: "#3f71c0" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface UpcomingVisitsTableToolbarProps {
  numSelected: number;
  onHandleDeleteSchedule: any;
}

const UpcomingVisitsTableToolbar = (props: UpcomingVisitsTableToolbarProps) => {
  const { numSelected, onHandleDeleteSchedule } = props;

  return (
    <Toolbar
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
      }}
    >
      {numSelected > 0 ? (
        <Typography
          color="inherit"
          variant="subtitle1"
          component="div"
          style={{ fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.54)" }}
        >
          {numSelected} selected
        </Typography>
      ) : (
        <></>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteRounded onClick={onHandleDeleteSchedule} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>{/* <FilterListIcon /> */}</IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

interface TableProps {
  scheduledVisits: any;
  onHandleDeleteSchedule: any;
  getScheduleReqId: any;
  deleteSchedule: boolean;
}

export default function ScheduledVisistsTable({
  scheduledVisits,
  onHandleDeleteSchedule,
  getScheduleReqId,
  deleteSchedule,
}: TableProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("startDate");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  let defaultLocation: IDefaultLocation = {
    regionId: "",
    locationId: "",
  };

  defaultLocation = useContext(DefaultLocation);

  const rows: any[] = [];

  scheduledVisits.forEach((visit: any) => {
    const v: any = createData(
      visit.startDate,
      visit.endDate,
      visit.timeSlot,
      visit.location,
      visit.reqId,
      ""
    );
    rows.push(v);
  });

  //   const handleRequestSort = (
  //     event: React.MouseEvent<unknown>,
  //     property: keyof Data
  //   ) => {
  //     const isAsc = orderBy === property && order === "asc";
  //     setOrder(isAsc ? "desc" : "asc");
  //     setOrderBy(property);
  //   };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows
        .map((n) => {
          const sd = new Date(n.startDate.split("T")[0]);
          const ed = new Date(n.endDate.split("T")[0]);
          const td = new Date();
          if (+sd <= +td && +td >= +ed) {
            return "";
          }
          return n.reqId;
        })
        .filter((n) => n);

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  const handleDeleteSchedule = async () => {};

  useEffect(() => {
    getScheduleReqId(selected);
  }, [selected]);

  useEffect(() => {
    if (deleteSchedule) {
      return setSelected((prevValue) => prevValue);
    }
    setSelected([]);
  }, [deleteSchedule]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box>
      <Paper>
        <UpcomingVisitsTableToolbar
          numSelected={selected.length}
          onHandleDeleteSchedule={onHandleDeleteSchedule}
        />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <UpcomingVisitsTableHead
              numSelected={selected.length}
              order={order}
              orderBy={"orderBy"}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={() => {}}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                rows.slice().sort(getComparator(order, orderBy)) */}
              {scheduledVisits.map((row: any, index: any) => {
                const isItemSelected = isSelected(row.reqId);
                const labelId = `enhanced-table-checkbox-${index}`;

                const startdate = new Date(row.startDate.split("T")[0]);
                const enddate = new Date(row.endDate.split("T")[0]);
                const todaysDate = new Date();

                const month1 = !+`${row.startDate}`
                  .split("T")[0]
                  .split("-")[1]
                  .split("")[0]
                  ? months[
                      +`${row.startDate}`
                        .split("T")[0]
                        .split("-")[1]
                        .split("")[1] - 1
                    ]
                  : months[+`${row.startDate}`.split("T")[0].split("-")[1] - 1];

                const month2 = !+`${row.endDate}`
                  .split("T")[0]
                  .split("-")[1]
                  .split("")[0]
                  ? months[
                      +`${row.endDate}`
                        .split("T")[0]
                        .split("-")[1]
                        .split("")[1] - 1
                    ]
                  : months[+`${row.endDate}`.split("T")[0].split("-")[1] - 1];

                return (
                  <TableRow
                    hover
                    onClick={(event) => {
                      if (
                        +startdate <= +todaysDate &&
                        +todaysDate >= +enddate
                      ) {
                        return;
                      }
                      handleClick(event, row.reqId);
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.startDate}
                    selected={isItemSelected}
                    style={{
                      backgroundColor: isItemSelected
                        ? "rgb(208 226 255)"
                        : "#fff",
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={
                          +startdate <= +todaysDate && +todaysDate >= +enddate
                            ? false
                            : isItemSelected
                        }
                        disabled={
                          +startdate <= +todaysDate && +todaysDate >= +enddate
                            ? true
                            : false
                        }
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      align="center"
                      padding="none"
                    >
                      {`${row.startDate}`.split("T")[0].split("-")[2] +
                        "-" +
                        month1 +
                        "-" +
                        `${row.startDate}`.split("T")[0].split("-")[0]}
                    </TableCell>
                    <TableCell align="center">
                      {`${row.endDate}`.split("T")[0].split("-")[2] +
                        "-" +
                        month2 +
                        "-" +
                        `${row.endDate}`.split("T")[0].split("-")[0]}
                    </TableCell>
                    <TableCell align="center">{row.timeSlot}</TableCell>
                    <TableCell align="center">{row.location}</TableCell>
                    <TableCell align="center">
                      <Button
                        size="small"
                        color="primary"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          if (
                            +startdate <= +todaysDate &&
                            +todaysDate >= +enddate
                          ) {
                            getScheduleReqId([null]);
                            onHandleDeleteSchedule();
                            return;
                          }
                          getScheduleReqId([row.reqId]);
                          onHandleDeleteSchedule();
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Box>
  );
}
