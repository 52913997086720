export class GetLocationResponse{
    public static getParsedResult(result: any): GetLocationServiceReponse {
        let parsedResult: GetLocationServiceReponse = (result) as GetLocationServiceReponse;
        return parsedResult;
    }
}

export class GetLocationServiceReponse{
    constructor(data:any){
        this.status = data.status;
        this.locations = data.locations;
    }
    status:string;
    locations:Location[]
}

class Location{
    constructor(data:any){
        this.id = data.id;
        this.name = data.name;
    }
    id:string;
    name:string;
}

//region

export class GetRegionResponse {
    public static getParsedResult(result: any): GetRegionServiceReponse {
        let parsedResult: GetRegionServiceReponse = (result) as GetRegionServiceReponse;
        return parsedResult;
    }
}

export class GetRegionServiceReponse {
    constructor(data: any) {
        this.status = data.status;
        this.locations = data.locations;
    }
    status: string;
    locations: Region[]
}

class Region {
    constructor(data: any) {
        this.regionid = data.regionid;
        this.name = data.name;
    }
    regionid: string;
    name: string;
}