import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { purple } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Rubik',
            'sans - serif',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#3f71c0'
        },
        secondary: {
            main: '#11cb5f',
        },
    },
});

export default theme;