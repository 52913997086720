import React, { useCallback, useEffect } from 'react';
import CommonPage from '../CommonUtils/CommonPage';
import {pageType} from '../../APIurls';


const EmployeeSchedule = () => {

    return(
        <>
       <CommonPage
        title = "Employee Schedule"
        subtitle = "Employee Schedule"
        type = {pageType.EMPLOYEE_SCHEDULE}
        hasNotifyButton={true}
        />
       </>
    );
}

export default EmployeeSchedule;