import { Configuration, PopupRequest, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
       // clientId: 'dbd386ad-8c3d-4ca2-8a18-9c2c80660c5f',
        clientId: `${process.env.REACT_APP_CLIENTID}`,
        authority: `${process.env.REACT_APP_authority}`,
        //authority:'https://login.microsoftonline.com/d78aee32-8f91-4f9e-90ea-fb72965d9d7c/',
        //redirectUri: "https://ingramrtww-dev.azurewebsites.net/"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ['openid profile offline_access user.read directory.read.all']
};
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me?\$select=id,displayName,givenName,mail,employeeId"
};