import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DeleteRounded } from "@material-ui/icons";
import React from "react";

interface Data {
  startDate: string;
  visitType: string;
  reasonToVisitOffice: string;
  location: string;
  status: string;
  action: any;
  reqId: string;
  isFullDay: string;
}

function createData(
  startDate: string,
  visitType: string,
  reasonToVisitOffice: string,
  location: string,
  status: string,
  action: any,
  reqId: string,
  isFullDay: string
): Data {
  return {
    startDate,
    visitType,
    reasonToVisitOffice,
    location,
    status,
    action,
    reqId,
    isFullDay,
  };
}

const rows = [
  createData(
    "2022-03-12",
    "Full day",
    "laptop repair",
    "KM",
    "pending",

    <Button>Skip</Button>,
    "",
    ""
  ),
];

export const months: string[] = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "startDate",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "visitType",
    numeric: true,
    disablePadding: false,
    label: "Visit Type",
  },
  {
    id: "reasonToVisitOffice",
    numeric: true,
    disablePadding: false,
    label: "Reason To Visit Office",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

interface UpcomingVisitsTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  onHandleSkipManagerApproval: any;
}

function UpcomingVisitsTableHead(props: UpcomingVisitsTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    onHandleSkipManagerApproval,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <div
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "15% 10% 40% 5% 20% 10%",
      }}
    >
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          align="center"
          padding="none"
          style={{ color: "#3f71c0", paddingTop: "24px" }}
        >
          {headCell.label}
        </TableCell>
      ))}
    </div>
  );
}

interface UpcomingVisitsTableToolbarProps {
  numSelected: number;
}

const UpcomingVisitsTableToolbar = (props: UpcomingVisitsTableToolbarProps) => {
  const { numSelected } = props;

  return <Toolbar></Toolbar>;
};

interface TableProps {
  adhocVisits: any;
  onHandleSkipManagerApproval: any;
  getAdhocReqId: any;
}

export default function AdhocVisistsTable({
  adhocVisits,
  onHandleSkipManagerApproval,
  getAdhocReqId,
}: TableProps) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("startDate");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  //   const handleRequestSort = (
  //     event: React.MouseEvent<unknown>,
  //     property: keyof Data
  //   ) => {
  //     const isAsc = orderBy === property && order === "asc";
  //     setOrder(isAsc ? "desc" : "asc");
  //     setOrderBy(property);
  //   };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.reqId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: any) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box>
      <Paper>
        <UpcomingVisitsTableHead
          numSelected={selected.length}
          order={order}
          orderBy={"orderBy"}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={() => {}}
          rowCount={rows.length}
          onHandleSkipManagerApproval={onHandleSkipManagerApproval}
        />
        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
        {adhocVisits.map((row: any, index: any) => {
          const isItemSelected = isSelected(row.reqId);
          const labelId = `enhanced-table-checkbox-${index}`;

          const month = !+`${row.startDate}`
            .split("T")[0]
            .split("-")[1]
            .split("")[0]
            ? months[
                +`${row.startDate}`.split("T")[0].split("-")[1].split("")[1] - 1
              ]
            : months[+`${row.startDate}`.split("T")[0].split("-")[1] - 1];

          return (
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "15% 10% 40% 5% 20% 10%",
              }}
            >
              <TableCell align="center">
                {`${row.startDate}`.split("T")[0].split("-")[2] +
                  "-" +
                  month +
                  "-" +
                  `${row.startDate}`.split("T")[0].split("-")[0]}
              </TableCell>
              <TableCell align="center">{row.isFullDay}</TableCell>
              <TableCell align="center" style={{ wordWrap: "break-word" }}>
                {row.reasonToVisitOffice}
              </TableCell>
              <TableCell align="center">{row.location}</TableCell>
              <TableCell align="center">
                {row.status === null
                  ? "Pending With Manager"
                  : row.status === 0
                  ? "Rejected"
                  : row.status === 1
                  ? "Pending With BCP"
                  : row.status === 2
                  ? "Approved"
                  : row.status === 3
                  ? "Self Declaration Failed"
                  : "Resubmit to admin"}
              </TableCell>
              <TableCell align="center">
                {row.status === 1 ||
                row.status === 2 ||
                row.status === 3 ||
                row.status === 0 ? (
                  <div style={{ marginRight: "4rem" }}></div>
                ) : (
                  <Button
                    color="primary"
                    onClick={() => {
                      onHandleSkipManagerApproval();
                      getAdhocReqId(row.reqId);
                    }}
                    disabled={
                      row.status === 1 ||
                      row.status === 2 ||
                      row.status === 3 ||
                      row.status === 0
                        ? true
                        : false
                    }
                  >
                    Skip
                  </Button>
                )}
              </TableCell>
            </div>
          );
        })}
        {emptyRows > 0 && (
          <TableRow
            style={{
              height: (dense ? 33 : 53) * emptyRows,
            }}
          >
            <TableCell colSpan={6} />
          </TableRow>
        )}

        {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
      </Paper>
    </Box>
  );
}
