import React, { useCallback, useEffect } from 'react';
import CommonPage from '../CommonUtils/CommonPage';
import {pageType} from '../../APIurls';


const EmployeeOnboard = () => {

    return(
       <>
       <CommonPage
        title = "Employee Onboarding"
        subtitle = "Employee onboard data"
        type = {pageType.ONBOARDING}
        hasNotifyButton = {false}
       />
       </> 
    );
    
}

export default EmployeeOnboard;