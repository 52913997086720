import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Variant } from '@material-ui/core/styles/createTypography';

interface titleprops{
    children: ReactNode,
    variant: Variant
    color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
}

export default function Title(props:titleprops) {
  return (
      <Typography component="h2" variant={props.variant} color={props.color ? props.color: "primary"} gutterBottom>
      {props.children}
    </Typography>
  );
}

