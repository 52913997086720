import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import {
  Backdrop,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Fade,
  Button,
} from "@material-ui/core";

//interface SetDateRangeProps {
//    display  : Boolean
//}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  mainDiv: {
    display: "flex",
    width: "fit-content",
    margin: "-16px",
    alignItems: "baseline",
  },
  dropdown: {
    margin: theme.spacing(2),
    width: "fit-content",
  },
  button: {
    margin: theme.spacing(1),
    width: "fit-content",
  },
  DateValues: {
    marginLeft: theme.spacing(2),
    position: "relative",
    zIndex: 100,
  },
}));

export interface IDateRange {
  startDate: string;
  endDate: string;
}
interface dateRangeProps {
  setDateRange: (dateRange: IDateRange) => void;
}
export default function SetDateRange(props: dateRangeProps) {
  const classes = useStyles();

  const emptyDateRange: IDateRange = {
    startDate: "",
    endDate: "",
  };

  const [type, setType] = React.useState("0");
  const [CustomLabel, setCustomLabel] = React.useState("no");
  const [dateRange, setDateRange] = React.useState(emptyDateRange);
  const [state, setState] = React.useState({
    selection: {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  });
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (setDate: string) => {
    setOpen(false);
    if (setDate === "yes") {
      setDateRange({
        startDate: state.selection.startDate.toLocaleDateString("en-ZA"),
        endDate: state.selection.endDate.toLocaleDateString("en-ZA"),
      });
      props.setDateRange(dateRange);
      setCustomLabel("yes");
    } else if (setDate === "no") {
      setDateRange({
        startDate: "",
        endDate: "",
      });
      props.setDateRange(dateRange);
      setCustomLabel("no");
    }
  };

  const handleDateChange = (item: any) => {
    setState(item);
  };

  const todaysDate: string = new Date().toLocaleDateString("en-ZA");
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const thismonth: IDateRange = {
    startDate: firstDay.toLocaleDateString("en-ZA"),
    endDate: lastDay.toLocaleDateString("en-ZA"),
  };
  const yesterday = new Date(
    date.setDate(date.getDate() - 1)
  ).toLocaleDateString("en-ZA");
  const thisWeek: IDateRange = {
    startDate: new Date(
      date.setDate(date.getDate() - date.getDay())
    ).toLocaleDateString("en-ZA"),
    endDate: new Date(
      date.setDate(date.getDate() - date.getDay() + 6)
    ).toLocaleDateString("en-ZA"),
  };

  const handleDateRange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setType(event.target.value as string);
    const rangeType = event.target.value as string;
    //    setDates();
    //}
    //const setDates = () => {
    switch (rangeType) {
      case "today":
        setDateRange({
          startDate: todaysDate,
          endDate: todaysDate,
        });
        break;
      case "yesterday":
        setDateRange({
          startDate: yesterday,
          endDate: yesterday,
        });
        break;
      case "thisWeek":
        setDateRange(thisWeek);
        //    {
        //    startDate: ThisWeek.startDate,
        //    endDate: ThisWeek.endDate
        //});
        break;
      case "thisMonth":
        setDateRange(thismonth);
        //    {
        //    startDate: thismonth.startDate,
        //    endDate: thismonth.endDate,
        //});
        break;
      case "custom":
        handleOpen();
        break;
      default:
        setDateRange(emptyDateRange);
        break;
    }
  };
  useEffect(() => {
    props.setDateRange(dateRange);
  }, [dateRange]);
  return (
    <>
      <div className={classes.mainDiv}>
        <div className={classes.dropdown}>
          <Select
            id="SelectDate"
            value={type ? type : "0"}
            onChange={handleDateRange}
          >
            <MenuItem key="0" value="0">
              Select Date
            </MenuItem>
            <MenuItem key="today" value="today">
              Today
            </MenuItem>
            <MenuItem key="yesterday" value="yesterday">
              Yesterday
            </MenuItem>
            <MenuItem key="thisWeek" value="thisWeek">
              Current Week
            </MenuItem>
            <MenuItem key="thisMonth" value="thisMonth">
              Current Month
            </MenuItem>
            <MenuItem key="custom" value="custom" onClick={handleOpen}>
              {CustomLabel === "yes"
                ? "" +
                  dateRange.startDate.split("/").reverse().join("/") +
                  " - " +
                  dateRange.endDate.split("/").reverse().join("/")
                : "Custom"}
            </MenuItem>
          </Select>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <DateRange
                editableDateInputs={true}
                onChange={(item) => handleDateChange(item)}
                moveRangeOnFirstSelection={false}
                ranges={[state.selection]}
              />
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    handleClose("no");
                  }}
                >
                  cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    handleClose("yes");
                  }}
                >
                  apply
                </Button>
              </div>
            </div>
          </Fade>
        </Modal>
        {/* displayDatePicker
                ?
                    <div className={classes.DateValues}>
                    <DateRange
                        editableDateInputs={true}
                        onChange={item => handleDateChange(item)}
                        moveRangeOnFirstSelection={false}
                        ranges={[state.selection]}
                    />
                </div>
            :
                <div className={classes.DateValues}>
                    <Title variant="subtitle1" color="textPrimary" >
                        {"" + dateRange.startDate + " - " + dateRange.endDate}
                    </Title>
                </div>
                
                 */}
      </div>
    </>
  );
}
