import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Title from '../CommonUtils/title';
import { apiURL } from '../../APIurls';
import { FetchWithTimeout } from '../CommonUtils/fetchWithTimeout';
import CustomizedSnackbar from '../CommonUtils/snackbar';
import CachedIcon from '@material-ui/icons/Cached';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { DefaultLocation } from '../../App';

interface liveUpdatesProps {
    locationid: string,
    setReload: (id: string) => void;
}
const useStyles = makeStyles((theme) => ({
    tile: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        background: '#fdfff4',
        textAlign: 'center',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius: '10px',
        minWidth: '200px',
    },
    Active: {
        borderColor: '#3f71c0',
    },
    AdhocVisits: {
        borderColor: '#3f71c0',
    },
    AcessDenied: {
        borderColor: '#ff2626',
    },
    occupancy: {
        borderColor: '#3f71c0',
    },
    ToggleButtonGroup: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
    },
    Togglebutton: {
        color: 'rgb(0 0 0)',
        background: '#3f71c0',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150
    },
    alignInRow: {
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline"
    },
    title: {
        display: "flex",
        
    },
    uploadbutton: {
        margin: theme.spacing(0),
        padding: '',
    },

}));

interface Idata {
    active: number,
    adhoc: number,
    dayid: Date,
    planned: number,
    total: number,
    denied: number,
}
interface IResult {
    status: string,
    message: string
}
export default function LiveUpdates(props: liveUpdatesProps) {
    const classes = useStyles();
    let date: Date = new Date();
    let emptyLiveData: Idata = {
        active: 0,
        adhoc: 0,
        dayid: date,
        planned: 0,
        total: 0,
        denied: 0,
    }
    let emptyResult: IResult = {
        status: "",
        message: "",
    };

    const [liveData, setLiveData] = React.useState(emptyLiveData);
    const [reload, setReload] = React.useState('');

    const [result, setResult] = React.useState(emptyResult);
    const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }
    const defaultLocation = useContext(DefaultLocation);

    useEffect(
        () => {
            if (props.locationid !== '') {
                //let io = require("socket.io-client");
                //let socket = io(apiURL.GET_LIVEUPDATES);
                //socket.on("connection", () => {
                //    console.log('connected');
                //});
                //socket.on("response", (data: any) => {
                //    if (data !== null) {
                //        setLiveData(data);                        
                //    }
                //    else {
                //        setLiveData(emptyLiveData);
                //    }
                //});
                let todaysDate: string = "" + new Date().getFullYear() + "/" + (new Date().getUTCMonth() + 1).toString().padStart(2, "0") + "/" + new Date().getDate().toString().padStart(2, "0");
                //socket.emit("data", { locationid: props.locationid, date: todaysDate });
                const getLiveData = async () => {
                    try {
                        const reqBody = {
                            locationid: props.locationid,
                            date: todaysDate
                        };
                        const response = await FetchWithTimeout(apiURL.GET_LIVEUPDATES, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                "Access-Control-Allow-Origin": "*",
                                "Authorization": `Bearer ${defaultLocation.accessToken}`, 
                            },
                            body: JSON.stringify(reqBody),
                            mode: 'cors',
                        })
                        return response.json();
                    } catch (error) {
                        // Timeouts if the request takes
                        // longer than 6 seconds
                        error.name === 'AbortError' ?
                            setResult({
                                status: "Failed",
                                message: "Your request has timed out. Try again in sometime."
                            })
                            : setResult({
                                status: "Failed",
                                message: "Oops!! Something went wrong."
                            });
                        setSnackbarOpen(true);
                    }
                }
                
                setReload('no');
                getLiveData().then(data => {
                    if (typeof (data) == typeof (emptyLiveData)) {
                        setLiveData(data);
                    }
                    else {
                        setLiveData(emptyLiveData);
                        setResult({
                            status: "Failed",
                            message: "Oops!! Something went wrong."
                        });
                        setSnackbarOpen(true);
                    }
                });
                var interval = setInterval(() => {
                    getLiveData().then(data => {
                        if (typeof (data) == typeof (emptyLiveData)) {
                            setLiveData(data);
                        }
                        else {
                            setLiveData(emptyLiveData);
                            setResult({
                                status: "Failed",
                                message: "Oops!! Something went wrong."
                            });
                            setSnackbarOpen(true);
                        }
                    });
                }, 300000);
                //}, 1000);
                return () => {

                    clearInterval(interval);
                    //socket.disconnect();
                }
                
            }
        }, [props.locationid, reload]);

    const refresh = () => {
        setReload('yes');
        props.setReload('yes');
    }

    return (
        <>
            <div className={classes.title}>
                <Title variant="h5">Today's Updates</Title>
                <div style={{paddingLeft: "49.5%"}}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.uploadbutton}
                        startIcon={<CachedIcon />}
                        onClick={() => refresh()}>
                        refresh
                    </Button>
                </div>

            </div>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={3}
            >
                <div className={clsx(classes.tile, classes.Active)}>
                    <Title variant="h6">Active At Work</Title>
                    <div className={classes.alignInRow}>
                        <Title variant="h3" color="textPrimary">{liveData.active} </Title>
                        <Title variant="h6" color="textSecondary"> of {(+liveData.planned + +liveData.adhoc)}</Title>
                    </div>

                </div>
                <div className={clsx(classes.tile, classes.AdhocVisits)}>
                    <Title variant="h6"> Adhoc Visits</Title>
                    <Title variant="h3" color="textPrimary">{liveData.adhoc}</Title>
                </div>
                <div className={clsx(classes.tile, classes.AcessDenied)}>
                    <Title variant="h6">Access Denied</Title>
                    <Title variant="h3" color="textPrimary">{liveData.denied}</Title>
                </div>
                <div className={clsx(classes.tile, classes.occupancy)}>
                    <Title variant="h6">Occupancy</Title>
                    <Title variant="h3" color="textPrimary">{((liveData.total == 0) ? "0" : (((liveData.active / liveData.total) * 100).toFixed(1))) + "%"}</Title>
                </div>
            </Grid>
            <CustomizedSnackbar status={result.status} message={result.message} displaySnackbar={isSnackbarOpen} handleClose={handleSnackbarClose} />
        </>
    );
}






