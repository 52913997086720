import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../components/CommonUtils/Images/logo.png';
import logoText from '../components/CommonUtils/Images/logoText.png';
import gridBackground from '../components/CommonUtils/Images/gridBackground.png';
import { Paper } from '@material-ui/core';
import Title from "../components/CommonUtils/title";

interface IResult {
    status: string;
    message: string;
}
const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100vh',
        overflow: "hidden"
    },
    logoDiv: {
        display: 'flex',
        alignItems: 'center',
        background: '#fdfff4',
    },
    logo: {
        maxWidth: '200px',
        maxHeight: '200px'
    },
    logoText: {
        maxWidth: '700px',
        maxHeight: '100px',
        paddingLeft: '15px'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        background: '#fdfff4',
        backgroundImage: "url(" + gridBackground + ")",
        backgroundPositionX: 'left',
        backgroundPositionY: 'bottom',
        backgroundSize: '50%',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        overflow: "hidden",
    },
}));


export default function Error(props :IResult) {
    const classes = useStyles();
    return (
        <>
            <div className={classes.container}>
                <Paper className={classes.paper}>
                    <div className={classes.logoDiv}>
                        <img src={logo} className={classes.logo} />
                        <img src={logoText} className={classes.logoText} />
                    </div>
                    <div>
                        {props.status === "Failed"?
                            < Title variant="h4" color="error">
                                {props.message}
                            </Title>
                            :   < Title variant="h4" color="error">
                                You do not have enough privileges to login
                                </Title>

                    }
                        
                    </div>
                </Paper>
            </div>
        </>
    );
}