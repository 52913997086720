import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Tab,
  Typography,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { Form, Formik } from "formik";
import { UserInput } from "./form";
import * as yup from "yup";
import SetDateRange, { DatePicker, IDateRange } from "./dateRange";

import { OnboardingDetails, SelfDeclarationDetails } from "./searchEmployee";
import ScheduledVisistsTable from "./scheduledVisitsTable";
import AdhocVisistsTable from "./adhocVisitTable";
import { FetchWithTimeout } from "../CommonUtils/fetchWithTimeout";
import { apiURL } from "../../APIurls";
import { DefaultLocation } from "../../App";
import { IDefaultLocation } from "../CommonUtils/LocationRenderer/Location";

const selfDeclaration = [
  "Have you traveled to a highly Covid infected area within the last 14 days?",
  "Did you expose yourself to Covid Positive person within the last 14 days?",
  "Do you have any symptoms of cold cough, fever, short breath?",
  "Do you have headache, nasal congestion or any other covid symptoms?",
  "Mode of transport ",
];

interface TabsProps {
  onHandleResetSelfDeclaration: any;
  onHandleSkipManagerApproval: any;
  onHandleDeleteSchedule: any;
  onboardingDetails: OnboardingDetails;
  employeeId: string;
  isClicked: boolean;
  getScheduleReqId: any;
  getAdhocReqId: any;
  getDateRange: any;
  getEmployeeDetails: any;
  todaysVisit: number;
  deleteSchedule: boolean;
  managerApproval: boolean;
  resetSelfDeclaration: boolean;
}

interface DeclarationProps {
  question: string;
  answer: string;
}

interface AdhocVisitProps {
  date?: string;
  visitType?: string;
  reason?: string;
  location: string;
  managerApproval?: boolean;
  startDate?: string;
  endDate?: string;
  timeSlot?: string;
  onHandleSkipManagerApproval?: any;
  onHandleDeleteSchedule?: any;
  type: string;
  status?: string;
}

interface FilterInputProps {
  onHandleFilter: any;
  value: string;
  getEmpScheduleOnVisitTypeChange: any;
}

interface IFiltered {
  date?: string;
  visitType: string;
  reason?: string;
  location: string;
  managerApproval?: boolean;
  startDate?: string;
  endDate?: string;
  timeSlot?: string;
}

interface VaccinationDateFormProp {
  firstshotdate: string;
  secondshotdate: string;
  employeeId: string;
  style?: any;
  getEmployeeDetails: any;
  handleSubmitClicked: any;
}

const Declaration = (props: DeclarationProps) => {
  const useStyle = makeStyles((theme) => ({
    wrapper: {
      display: "grid",
      gridTemplateColumns: "95% 5%",

      width: "60%",
    },

    textSize: {
      fontSize: "1rem",
    },

    font: {
      fontSize: "1rem",
      fontWeight: 600,
    },
  }));

  const classes = useStyle();

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.textSize} variant="h6">
        {props.question}
      </Typography>
      <Typography variant="h6" className={classes.font}>
        {props.answer}
      </Typography>
    </div>
  );
};

const AdhocVisit = (props: AdhocVisitProps) => {
  const useStyle = makeStyles((theme) => ({
    textSize: {
      fontSize: "1rem",
    },

    wrapper: {
      display: "grid",
      gridTemplateColumns: "10% 20% 20% 20% 20% 10%",
      placeItems: "center",
    },
    wrapper1: {
      display: "grid",
      gridTemplateColumns: "20% 20% 20% 20% 20%",
      placeItems: "center",
    },

    button: {
      margin: "0.2rem",
    },
  }));

  const classes = useStyle();

  const visitValues = Object.values(props).filter(
    (prop) => typeof prop === "string"
  );

  visitValues.pop();

  return (
    <div
      className={
        props.type === "Scheduled" ? classes.wrapper1 : classes.wrapper
      }
    >
      {visitValues.map((value, i) => (
        <Typography key={i} className={classes.textSize} variant="h6">
          {value}
        </Typography>
      ))}
      {props.type === "Adhoc" ? (
        <Button
          className={classes.button}
          color="primary"
          size="small"
          onClick={props.onHandleSkipManagerApproval}
          disabled={props.managerApproval ? true : false}
        >
          Skip
        </Button>
      ) : props.type === "Scheduled" ? (
        <Button
          className={classes.button}
          color="primary"
          size="small"
          onClick={props.onHandleDeleteSchedule}
        >
          Delete
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};

const VaccinationDateForm = ({
  firstshotdate,
  secondshotdate,
  employeeId,
  style,
  getEmployeeDetails,
  handleSubmitClicked,
}: VaccinationDateFormProp) => {
  const firstShot: string = firstshotdate?.split("T")[0];
  const secondShot: string = secondshotdate?.split("T")[0];
  const initVal: any = {
    firstDose: firstShot,
    secondDose: secondShot,
  };
  const [initialValues, setInitialValues] = useState(initVal);

  const validate = yup.object({
    firstDose: yup.string().required("*"),
    secondDose: yup.string(),
  });

  let defaultLocation: IDefaultLocation = {
    regionId: "",
    locationId: "",
  };

  defaultLocation = useContext(DefaultLocation);

  const useStyle = makeStyles(() => ({
    wrapper: {
      display: "grid",
      gridTemplateColumns: "31% 31% 15%",
    },

    container: {
      width: "70%",

      padding: "1rem 0",
    },

    visible: {
      margin: "1rem",

      display: "block",
    },

    invisible: {
      display: "none",
    },
  }));

  const classes = useStyle();

  const updateVaccinationDate = async (values: any) => {
    await FetchWithTimeout(apiURL.UPDATE_EMPLOYEEDETAILS, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${defaultLocation.accessToken}`,
      },
      body: JSON.stringify(values),
      mode: "cors",
    });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        validateOnChange
        onSubmit={async (values, onSubmitProps) => {
          await updateVaccinationDate({ ...values, empId: employeeId });
          setInitialValues(values);

          const response = await FetchWithTimeout(apiURL.GET_EMPPROFILE, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${defaultLocation.accessToken}`,
            },
            body: JSON.stringify({
              employeeId: employeeId,
            }),
            mode: "cors",
          });

          const data = await response.json();

          getEmployeeDetails(data.Result);

          handleSubmitClicked();
          onSubmitProps.resetForm();
          onSubmitProps.setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form className={classes.container}>
            <div className={classes.wrapper}>
              <UserInput
                style={style}
                label="First Dose"
                name="firstDose"
                type="date"
                max={new Date()}
              />
              <UserInput
                style={style}
                label="Second Dose"
                name="secondDose"
                type="date"
                min={formik.values.firstDose}
              />

              <Button
                className={
                  formik.initialValues.firstDose === formik.values.firstDose &&
                  formik.initialValues.secondDose === formik.values.secondDose
                    ? classes.invisible
                    : classes.visible
                }
                variant="contained"
                type="submit"
                color="primary"
                style={{
                  width: "3rem",
                  height: "2rem",

                  padding: "0",

                  fontSize: "0.8rem",
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const FilterInput = ({
  onHandleFilter,
  value,
  getEmpScheduleOnVisitTypeChange,
}: FilterInputProps) => {
  const handleFilter = (e: any) => {
    onHandleFilter(e.target.value);
  };

  return (
    <Select value={value} onChange={handleFilter}>
      {["Adhoc", "Scheduled"].map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );
};

const Tabs = (props: TabsProps) => {
  let sd: any = null;
  let v: any = null;
  const [value, setValue] = useState("1");
  const [visitType, setVisitType] = useState("Adhoc");
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [todaysSelfdeclaration, setTodaysSelfdeclaration] = useState(sd);
  const [upcommingSchedules, setUpcommingSchedules] = useState(v);
  const [firstDoseDate, setFirstDoseDate] = useState("");
  const [secondDoseDate, setSecondDoseDate] = useState("");
  const [isButtonAvailable, setIsButtonAvailable] = useState(false);

  let emptydateRange: IDateRange = {
    startDate: new Date().toLocaleDateString("en-ZA"),
    endDate: new Date().toLocaleDateString("en-ZA"),
  };

  const [dateRange, setDateRange] = React.useState(emptydateRange);

  const {
    onHandleResetSelfDeclaration,
    onHandleSkipManagerApproval,
    onHandleDeleteSchedule,
    onboardingDetails,
    employeeId,
    isClicked,
    getScheduleReqId,
    getAdhocReqId,
    getDateRange,
    getEmployeeDetails,
    todaysVisit,
    deleteSchedule,
    managerApproval,
    resetSelfDeclaration,
  } = props;

  const firstShot = onboardingDetails.firstshotdate
    ?.split("T")[0]
    .split("-")
    .reverse()
    .join("/");
  const newFirstShot = `${firstShot?.split("/").slice(0, 2).join("/")}/${
    firstShot?.split("/")[2]
  }`;

  const secondShot = onboardingDetails.secondshotdate
    ?.split("T")[0]
    .split("-")
    .reverse()
    .join("/");
  const newSecondShot = `${secondShot?.split("/").slice(0, 2).join("/")}/${
    secondShot?.split("/")[2]
  }`;

  let defaultLocation: IDefaultLocation = {
    regionId: "",
    locationId: "",
  };

  defaultLocation = useContext(DefaultLocation);

  const useStyle = makeStyles((theme) => ({
    title: {
      fontSize: "1rem",
      fontWeight: 600,

      color: "rgba(0, 0, 0, 0.54)",
    },

    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },

    filterContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      width: "30%",
    },

    wrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      width: "50%",
    },

    button: {
      marginLeft: "1rem",
    },

    edit: {
      marginLeft: "0.5rem",

      color: "#3f71c0",
      textDecoration: "underline",
      textAlign: "center",

      cursor: "pointer",
    },

    date: {
      fontSize: "1rem",
    },

    noVisits: {
      marginTop: "1rem",
    },

    inVisible: {
      display: "none",
    },
    dateWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",

      height: "4rem",

      marginLeft: "1rem",
    },
  }));

  const classes = useStyle();

  const getEmpTodaySelfDeclaration = async () => {
    const response = await FetchWithTimeout(apiURL.GET_TODAYSELFDECLARATION, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${defaultLocation.accessToken}`,
      },
      body: JSON.stringify({
        employeeId: employeeId,
      }),
      mode: "cors",
    });

    const data = await response.json();

    setTodaysSelfdeclaration(data?.Result?.todaysSelfdeclaration);
  };

  const getEmpSchedule = async () => {
    const response = await FetchWithTimeout(apiURL.GET_EMPSCHEDULE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${defaultLocation.accessToken}`,
      },
      body: JSON.stringify({
        employeeId: employeeId,
        visittype: visitType.toLowerCase(),
        startdate: dateRange.startDate,
        enddate: dateRange.endDate,
      }),
      mode: "cors",
    });

    const data = await response.json();

    setUpcommingSchedules(data.Result?.upcommingSchedules);
  };

  useEffect(() => {
    setValue("1");
  }, [isClicked, isSubmitClicked]);

  useEffect(() => {
    getEmpTodaySelfDeclaration();
    getEmpSchedule();
  }, []);

  useEffect(() => {
    getEmpTodaySelfDeclaration();
    getEmpSchedule();
    setVisitType("Adhoc");
  }, [employeeId]);

  useEffect(() => {
    getEmpSchedule();
  }, [dateRange, visitType]);

  useEffect(() => {
    getEmpSchedule();
  }, [deleteSchedule]);

  useEffect(() => {
    getEmpSchedule();
  }, [managerApproval]);

  useEffect(() => {
    getEmpTodaySelfDeclaration();
  }, [resetSelfDeclaration]);

  useEffect(() => {
    if (
      (firstDoseDate === newFirstShot && secondDoseDate === newSecondShot) ||
      !firstDoseDate
    ) {
      return;
    }
    setIsButtonAvailable(true);
    return () => {
      setIsButtonAvailable(false);
    };
  }, [firstDoseDate, secondDoseDate]);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  const SetDateRangeFun = (dateRange: IDateRange) => {
    getDateRange(dateRange);
    setDateRange(dateRange);
  };

  const handleFilter = (value: string) => {
    setVisitType(value);
  };

  const handleSubmitClicked = () => {
    setIsSubmitClicked((prevVal) => !prevVal);
  };

  const getEmpScheduleOnVisitTypeChange = (value: any) => {
    setUpcommingSchedules(value);
  };

  const selfDeclarationDetails: SelfDeclarationDetails = {
    reqId: todaysSelfdeclaration?.length ? todaysSelfdeclaration[0].reqid : "",
    cough: todaysSelfdeclaration?.length ? todaysSelfdeclaration[0].cough : "",
    fever: todaysSelfdeclaration?.length ? todaysSelfdeclaration[0].fever : "",
    shortBreath: todaysSelfdeclaration?.length
      ? todaysSelfdeclaration[0].shortbreath
      : "",
    soreThroat: todaysSelfdeclaration?.length
      ? todaysSelfdeclaration[0].sorethroat
      : "",
    exposedToInfectedPeople: todaysSelfdeclaration?.length
      ? todaysSelfdeclaration[0].exposedtoinfectedpeople
      : "",
    exposedToInfectedRegion: todaysSelfdeclaration?.length
      ? todaysSelfdeclaration[0].exposedtoinfectedregion
      : "",
    transport: todaysSelfdeclaration?.length
      ? todaysSelfdeclaration[0].transport
      : "",
    isSelfDeclarationDone: todaysSelfdeclaration?.length
      ? todaysSelfdeclaration[0].isselfdeclarationdone
      : "",
    isSelfDeclarationApproved: todaysSelfdeclaration?.length
      ? todaysSelfdeclaration[0].isselfdeclarationapproved
      : "",
    hasHeadache: todaysSelfdeclaration?.length
      ? todaysSelfdeclaration[0].headache
      : "",
    hasNasalCongestion: todaysSelfdeclaration?.length
      ? todaysSelfdeclaration[0].nasalcongestion
      : "",
    hasOtherCovidSymptoms: todaysSelfdeclaration?.length
      ? todaysSelfdeclaration[0].othercovidsymptoms
      : "",
  };

  const scheduledVisits: any = upcommingSchedules
    ?.filter((visit: any) => visit.visittype === "scheduled")
    .filter((visit: any) => {
      if (!dateRange.startDate && !dateRange.endDate) {
        return visit;
      }

      const dataStartDate = new Date(visit.startdate.split("T")[0]);
      const dataEndDate = new Date(visit.enddate.split("T")[0]);

      const filterStartDate = new Date(
        dateRange.startDate.split("/").join("-")
      );
      const filterEndDate = new Date(dateRange.endDate.split("/").join("-"));

      if (+filterStartDate === +filterEndDate) {
        return (
          +dataStartDate <= +filterStartDate && +dataEndDate >= +filterStartDate
        );
      }

      return (
        (+dataStartDate >= +filterStartDate &&
          +dataStartDate <= +filterEndDate &&
          +dataEndDate >= +filterStartDate &&
          +dataEndDate <= +filterEndDate) ||
        (+dataStartDate >= +filterStartDate &&
          +dataStartDate <= +filterEndDate &&
          +dataEndDate >= +filterStartDate &&
          +dataEndDate >= +filterEndDate) ||
        (+dataStartDate <= +filterStartDate &&
          +dataStartDate <= +filterEndDate &&
          +dataEndDate >= +filterStartDate &&
          +dataEndDate >= +filterEndDate) ||
        (+dataStartDate <= +filterStartDate &&
          +dataStartDate <= +filterEndDate &&
          +dataEndDate >= +filterStartDate &&
          +dataEndDate <= +filterEndDate)
      );
    })
    .map((visit: any) => ({
      startDate: visit.startdate,
      endDate: visit.enddate,
      timeSlot: visit.timeslot,
      location: visit.locationid,
      reqId: visit.reqid,
    }));

  const adhocVisits = upcommingSchedules
    ?.filter((visit: any) => visit.visittype === "adhoc")
    .filter((visit: any) => {
      if (!dateRange.startDate && !dateRange.endDate) {
        return visit;
      }

      const dataStartDate = new Date(visit.startdate.split("T")[0]);
      const dataEndDate = new Date(visit.enddate.split("T")[0]);

      const filterStartDate = new Date(
        dateRange.startDate.split("/").join("-")
      );
      const filterEndDate = new Date(dateRange.endDate.split("/").join("-"));

      if (+filterStartDate === +filterEndDate) {
        return (
          +dataStartDate <= +filterStartDate && +dataEndDate >= +filterStartDate
        );
      }

      return (
        (+dataStartDate >= +filterStartDate &&
          +dataStartDate <= +filterEndDate &&
          +dataEndDate >= +filterStartDate &&
          +dataEndDate <= +filterEndDate) ||
        (+dataStartDate >= +filterStartDate &&
          +dataStartDate <= +filterEndDate &&
          +dataEndDate >= +filterStartDate &&
          +dataEndDate >= +filterEndDate) ||
        (+dataStartDate <= +filterStartDate &&
          +dataStartDate <= +filterEndDate &&
          +dataEndDate >= +filterStartDate &&
          +dataEndDate >= +filterEndDate) ||
        (+dataStartDate <= +filterStartDate &&
          +dataStartDate <= +filterEndDate &&
          +dataEndDate >= +filterStartDate &&
          +dataEndDate <= +filterEndDate)
      );
    })
    .map((visit: any) => ({
      startDate: visit.startdate,
      reasonToVisitOffice: visit.reason,
      status: visit.isapproved,
      visitType: visit.visittype,
      location: visit.locationid,
      reqId: visit.reqid,
      isFullDay: visit.isfullday ? "Full Day" : "Urgent",
      action: (
        <Button
          color="primary"
          disabled={
            visit.isapproved === 1 || visit.isapproved === 2 ? true : false
          }
        >
          Skip
        </Button>
      ),
    }));

  const getFirstDoseDate = (value: string) => {
    setFirstDoseDate(value);
  };

  const getSecondDoseDate = (value: string) => {
    setSecondDoseDate(value);
  };

  const handleVaccinationForm = async () => {
    await FetchWithTimeout(apiURL.UPDATE_EMPLOYEEDETAILS, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${defaultLocation.accessToken}`,
      },
      body: JSON.stringify({
        empId: employeeId,
        firstDose: firstDoseDate.split("/").reverse().join("-"),
        secondDose: secondDoseDate.split("/").reverse().join("-"),
      }),
      mode: "cors",
    });

    const response = await FetchWithTimeout(apiURL.GET_EMPPROFILE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${defaultLocation.accessToken}`,
      },
      body: JSON.stringify({
        employeeId: employeeId,
      }),
      mode: "cors",
    });

    const data = await response.json();

    getEmployeeDetails(data.Result);

    setIsButtonAvailable(false);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChangeTab}
            aria-label="lab API tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label="Self Declaration"
              value="1"
              onClick={getEmpTodaySelfDeclaration}
            />
            <Tab label="Upcoming Visits" value="2" onClick={getEmpSchedule} />
            <Tab label="Onboarding Details" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <div className={classes.container}>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: "1rem",
              }}
              variant="h6"
            >
              {!todaysSelfdeclaration?.length
                ? "No Visit"
                : !selfDeclarationDetails.isSelfDeclarationDone &&
                  !selfDeclarationDetails.isSelfDeclarationDone
                ? "Self Declaration Not Done"
                : selfDeclarationDetails.isSelfDeclarationDone &&
                  !selfDeclarationDetails.isSelfDeclarationApproved
                ? "Self Declaration Failed"
                : "Self Declaration Successful"}
            </Typography>
            <a
              className={
                selfDeclarationDetails.isSelfDeclarationDone &&
                !selfDeclarationDetails.isSelfDeclarationApproved
                  ? classes.edit
                  : classes.inVisible
              }
              onClick={() => {
                onHandleResetSelfDeclaration(selfDeclarationDetails.reqId);
              }}
            >
              Reset
            </a>
          </div>

          <div>
            {!selfDeclarationDetails.isSelfDeclarationDone &&
            !selfDeclarationDetails.isSelfDeclarationDone ? (
              <></>
            ) : (
              <div>
                <Declaration
                  question={selfDeclaration[0]}
                  answer={
                    !selfDeclarationDetails.isSelfDeclarationDone &&
                    !selfDeclarationDetails.isSelfDeclarationDone
                      ? "NA"
                      : selfDeclarationDetails.exposedToInfectedRegion === false
                      ? "No"
                      : selfDeclarationDetails.exposedToInfectedRegion
                      ? "Yes"
                      : "NA"
                  }
                />
                <Declaration
                  question={selfDeclaration[1]}
                  answer={
                    !selfDeclarationDetails.isSelfDeclarationDone &&
                    !selfDeclarationDetails.isSelfDeclarationDone
                      ? "NA"
                      : selfDeclarationDetails.exposedToInfectedPeople === false
                      ? "No"
                      : selfDeclarationDetails.exposedToInfectedPeople
                      ? "Yes"
                      : "NA"
                  }
                />
                <Declaration
                  question={selfDeclaration[2]}
                  answer={
                    !selfDeclarationDetails.isSelfDeclarationDone &&
                    !selfDeclarationDetails.isSelfDeclarationDone
                      ? "NA"
                      : selfDeclarationDetails.cough === null &&
                        selfDeclarationDetails.fever === null &&
                        selfDeclarationDetails.shortBreath === null &&
                        selfDeclarationDetails.soreThroat === null
                      ? "NA"
                      : selfDeclarationDetails.cough === false &&
                        selfDeclarationDetails.fever === false &&
                        selfDeclarationDetails.shortBreath === false &&
                        selfDeclarationDetails.soreThroat === false
                      ? "No"
                      : selfDeclarationDetails.cough ||
                        selfDeclarationDetails.fever ||
                        selfDeclarationDetails.shortBreath ||
                        selfDeclarationDetails.soreThroat
                      ? "Yes"
                      : "NA"
                  }
                />
                <Declaration
                  question={selfDeclaration[3]}
                  answer={
                    !selfDeclarationDetails.isSelfDeclarationDone &&
                    !selfDeclarationDetails.isSelfDeclarationDone
                      ? "NA"
                      : selfDeclarationDetails.hasHeadache === null &&
                        selfDeclarationDetails.hasOtherCovidSymptoms === null &&
                        selfDeclarationDetails.hasNasalCongestion === null
                      ? "NA"
                      : selfDeclarationDetails.hasHeadache === false &&
                        selfDeclarationDetails.hasOtherCovidSymptoms ===
                          false &&
                        selfDeclarationDetails.hasNasalCongestion === false
                      ? "No"
                      : selfDeclarationDetails.hasHeadache ||
                        selfDeclarationDetails.hasOtherCovidSymptoms ||
                        selfDeclarationDetails.hasNasalCongestion
                      ? "Yes"
                      : "NA"
                  }
                />
                <Declaration
                  question={selfDeclaration[4]}
                  answer={
                    !selfDeclarationDetails.isSelfDeclarationDone &&
                    !selfDeclarationDetails.isSelfDeclarationDone
                      ? "NA"
                      : selfDeclarationDetails.transport === false
                      ? "Public"
                      : selfDeclarationDetails.transport
                      ? "Private"
                      : "NA"
                  }
                />
              </div>
            )}
          </div>
        </TabPanel>

        <TabPanel value="2">
          <div className={classes.filterContainer}>
            <FilterInput
              onHandleFilter={handleFilter}
              value={visitType}
              getEmpScheduleOnVisitTypeChange={getEmpScheduleOnVisitTypeChange}
            />
            <SetDateRange
              setDateRange={SetDateRangeFun}
              visitType={visitType}
            />
          </div>
          {visitType === "Scheduled" &&
          scheduledVisits &&
          scheduledVisits.length ? (
            <ScheduledVisistsTable
              scheduledVisits={scheduledVisits}
              onHandleDeleteSchedule={onHandleDeleteSchedule}
              getScheduleReqId={getScheduleReqId}
              deleteSchedule={deleteSchedule}
            />
          ) : visitType === "Adhoc" && adhocVisits && adhocVisits.length ? (
            <AdhocVisistsTable
              adhocVisits={adhocVisits}
              onHandleSkipManagerApproval={onHandleSkipManagerApproval}
              getAdhocReqId={getAdhocReqId}
            />
          ) : (
            <Typography
              className={classes.noVisits}
              variant="h5"
              align="center"
            >
              No Visits
            </Typography>
          )}
        </TabPanel>

        <TabPanel value="3">
          <Declaration
            question={"Have you been vaccinated?"}
            answer={onboardingDetails.isvaccinated === "1" ? "Yes" : "No"}
          />

          {/* <VaccinationDateForm
            firstshotdate={onboardingDetails.firstshotdate}
            secondshotdate={onboardingDetails.secondshotdate}
            employeeId={employeeId}
            getEmployeeDetails={getEmployeeDetails}
            handleSubmitClicked={handleSubmitClicked}
            style={{
              colorl: "#000",
              colort: "rgba(0, 0, 0, 0.54)",
            }}
          /> */}
          <div className={classes.dateWrapper}>
            <DatePicker
              getDate={getFirstDoseDate}
              labelName="First Dose"
              max={onboardingDetails.firstshotdate ? new Date() : new Date()}
              value={
                onboardingDetails.firstshotdate
                  ? onboardingDetails.firstshotdate
                  : ""
              }
            />
            <DatePicker
              getDate={getSecondDoseDate}
              labelName="Second Dose"
              max={new Date()}
              min={firstDoseDate}
              value={
                onboardingDetails.secondshotdate
                  ? onboardingDetails.secondshotdate
                  : ""
              }
              disabled={
                !onboardingDetails.firstshotdate && !firstDoseDate
                  ? true
                  : false
              }
            />
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ display: !isButtonAvailable ? "none" : "block" }}
              onClick={handleVaccinationForm}
            >
              Submit
            </Button>
          </div>

          <Declaration
            question="Are you classified as person with comorbidity?"
            answer={
              onboardingDetails.hasComorbidity === "1"
                ? "Yes"
                : onboardingDetails.hasComorbidity === "0"
                ? "No"
                : "NA"
            }
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Tabs;
