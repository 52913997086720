const apiUrl_base = process.env.REACT_APP_APIURL + "";

export const apiURL = {
  DOWNLOAD_TEMPLATE: `${apiUrl_base}/downloadtemplate`,
  EMPLOYEE_SCHEDULE: `${apiUrl_base}/uploademployeeschedule`,
  ONBOARDING: `${apiUrl_base}/uploademployeedetails`,
  // ONBOARDING: `https://apac.ingrammicro.com/HiveAPI/uploademployeedetails`,
  SEATING_DETAILS: `${apiUrl_base}/uploadseatingdetails`,
  GET_LOCATION: `${apiUrl_base}/getlocation`,
  GET_REGION: `${apiUrl_base}/getregion`,
  GET_BATCH: `${apiUrl_base}/getbatch`,
  SEND_ANNOUNCEMENTS: `${apiUrl_base}/sendannouncements`,
  GET_EMPLOYEEDETAILS: `${apiUrl_base}/getemployeedetails`,
  Get_COVIDPOSITIVE_REPORT: `${apiUrl_base}/getcovidpositive`,
  //Get_COVIDPOSITIVE_REPORT : `https://www.google.com:81/`,
  Get_ATTENDANCE_REPORT: `${apiUrl_base}/attendancereport`,
  Get_ACCESSDENIED_REPORT: `${apiUrl_base}/accessdeniedreport`,
  Get_VACCINATION_REPORT: `${apiUrl_base}/vaccinationreport`,
  Get_GRAPHDATA: `${apiUrl_base}/getgraphdata`,
  GET_LIVEUPDATES: `${apiUrl_base}/gettickerdata`,
  GET_SUGGESTIONS: `${apiUrl_base}/v2/searchEmployes`,
  GET_EMPPROFILE: `${apiUrl_base}/v2/getEmployeeProfileDetails`,
  RESET_SELFDECLARATION: `${apiUrl_base}/resetselfdeclaration`,
  UPDATE_EMPLOYEEDETAILS: `${apiUrl_base}/v2/updateEmployeeProfileDetails`,
  SKIP_MANAGERAPPROVAL: `${apiUrl_base}/v2/skipManagerApproval`,
  DELETE_SCHEDULE: `${apiUrl_base}/v2/deleteSchedule`,
  GET_TODAYSELFDECLARATION: `${apiUrl_base}/v2/getEmpTodaySelfDeclaration`,
  GET_EMPSCHEDULE: `${apiUrl_base}/v2/getEmployeeSchedule`,
  //GET_LIVEUPDATES : 'https://ingramrtw.azurewebsites.net',
};
/*export enum apiURL {
    DOWNLOAD_TEMPLATE = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/downloadtemplate',
    EMPLOYEE_SCHEDULE = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/uploademployeeschedule',
    ONBOARDING = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/uploademployeedetails',
    SEATING_DETAILS = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/uploadseatingdetails',
    GET_LOCATION = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/getlocation',
    GET_REGION = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/getregion',
    GET_BATCH = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/getbatch',
    SEND_ANNOUNCEMENTS = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/sendannouncements',
    GET_EMPLOYEEDETAILS = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/getemployeedetails',
    Get_COVIDPOSITIVE_REPORT = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/getcovidpositive',
    Get_ATTENDANCE_REPORT = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/attendancereport',
    Get_ACCESSDENIED_REPORT = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/accessdeniedreport',
}*/
/* export enum apiURL {
    DOWNLOAD_TEMPLATE = 'https://ingramrtw.azurewebsites.net/downloadtemplate',
    EMPLOYEE_SCHEDULE = 'https://ingramrtw.azurewebsites.net/uploademployeeschedule',
    ONBOARDING = 'https://ingramrtw.azurewebsites.net/uploademployeedetails',
    SEATING_DETAILS = 'https://ingramrtw.azurewebsites.net/uploadseatingdetails'
} */

export enum pageType {
  EMPLOYEE_SCHEDULE = "empSchedule",
  ONBOARDING = "onboarding",
  SEATING_DEATLS = "seatingData",
}
