import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import { MenuItem, Tooltip } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "@material-ui/core";
import {
  GetLocationResponse,
  GetRegionResponse,
} from "../CommonUtils/LocationRenderer/locationModels";
import { DefaultLocation } from "../../App";
import { apiURL } from "../../APIurls";
import CustomizedSnackbar from "../CommonUtils/snackbar";
import { FetchWithTimeout } from "../CommonUtils/fetchWithTimeout";
import { ErrorMessage, Field, useField } from "formik";

interface locationProps {
  regionid?: string;
  label: string;
  name1: any;
  name2: any;
  type: string;
  getLocationId: any;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),

    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
    minWidth: 120,
  },
  location: {
    display: "inline-block",
    minWidth: 150,
    position: "relative",
  },
  mainDiv: {
    display: "flex",
    justifyContent: "flex-start",
  },
  label: {
    fontSize: "1.1rem",
    fontWeight: 400,
    lineHeight: 1.47,
  },
}));
export interface IDefaultLocation {
  regionId: string;
  locationId: string;
  accessToken?: string;
}
export interface IRegion {
  value: string;
  label: string;
}

interface ILocation {
  value: string;
  label: string;
}

interface IResult {
  status: string;
  message: string;
}
export default function SelectLocation(props: locationProps) {
  const { label, getLocationId, name1, name2 } = props;

  let emptyRegionArray: IRegion[] = [];
  let emptyLocationArray: ILocation[] = [];
  let emptyResult: IResult = {
    status: "",
    message: "",
  };
  let defaultLocation: IDefaultLocation = {
    regionId: "",
    locationId: "",
  };
  defaultLocation = useContext(DefaultLocation);
  const classes = useStyles();

  const [locationItems, setLocationItems] = React.useState(emptyLocationArray);
  const [regionItems, setRegionItems] = React.useState(emptyRegionArray);

  const [result, setResult] = React.useState(emptyResult);
  const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);

  const locationField = useField(name1);
  const regionField = useField(name2);

  const region = regionItems[0]
    ? regionItems?.filter((item) => item?.label === regionField[0]?.value)[0]
        ?.value
    : "";

  console.log(regionItems);

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  React.useEffect(() => {
    const getRegion = async () => {
      try {
        const response = await FetchWithTimeout(apiURL.GET_REGION, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${defaultLocation.accessToken}`,
          },
          mode: "cors",
        });
        return response.json();
      } catch (error: any) {
        error.name === "AbortError"
          ? setResult({
              status: "Failed",
              message: "Your request has timed out. Try again in sometime.",
            })
          : setResult({
              status: "Failed",
              message: "Oops!! Something went wrong.",
            });
        setSnackbarOpen(true);
      }
    };
    getRegion().then((data) => {
      if (data) {
        let result = GetRegionResponse.getParsedResult(data);
        if (result.status == "Success") {
          if (result.locations && result.locations.length > 0) {
            let region: IRegion[] = [];
            result.locations.forEach((p) => {
              region.push({
                label: p.name,
                value: p.regionid,
              });
            });
            setRegionItems(region);
          }
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (region) {
      const getLocation = async () => {
        const reqBody = {
          regionid: region,
        };
        var data = new FormData();
        data.append("json", JSON.stringify(reqBody));
        try {
          const response = await FetchWithTimeout(apiURL.GET_LOCATION, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${defaultLocation.accessToken}`,
            },
            body: JSON.stringify(reqBody),
            mode: "cors",
          });
          return response.json();
        } catch (error: any) {
          error.name === "AbortError"
            ? setResult({
                status: "Failed",
                message: "Your request has timed out. Try again in sometime.",
              })
            : setResult({
                status: "Failed",
                message: "Oops!! Something went wrong.",
              });
          setSnackbarOpen(true);
        }
      };
      getLocation().then((data) => {
        if (data) {
          let result = GetLocationResponse.getParsedResult(data);
          if (result.status == "Success") {
            if (result.locations && result.locations.length > 0) {
              let location: ILocation[] = [];
              result.locations.forEach((p) => {
                location.push({
                  label: p.name,
                  value: p.id,
                });
              });
              setLocationItems(location);
              getLocationId(location);
            }
          }
        }
      });
    }
  }, [region]);

  return (
    <>
      <div className={classes.mainDiv}>
        <FormControl className={classes.formControl}>
          <div className={classes.location}>
            <InputLabel id="Select-Region" className={classes.label}>
              Region *
            </InputLabel>
            <Select
              labelId="Select-Region"
              id="selectRegion"
              className={classes.selectEmpty}
              {...regionField[0]}
              {...name2}
            >
              {regionItems.map((item) => (
                <MenuItem key={item.value} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </FormControl>
        <FormControl
          className={classes.formControl}
          disabled={!(region !== "" && region !== "0")}
        >
          <div className={classes.location}>
            <InputLabel id="Select-Location" className={classes.label}>
              Location *
            </InputLabel>
            <Tooltip
              title={!region ? "Please select region" : "select location"}
              arrow
              placement="top"
            >
              <Select
                labelId="Select-Location"
                id="selectLocation"
                className={classes.selectEmpty}
                {...locationField[0]}
                {...name1}
              >
                {locationItems.map((item) => (
                  <MenuItem key={item.value} value={item.label}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Tooltip>
          </div>
        </FormControl>
        <br />
        <CustomizedSnackbar
          status={result.status}
          message={result.message}
          displaySnackbar={isSnackbarOpen}
          handleClose={handleSnackbarClose}
        />
      </div>
    </>
  );
}

interface UserInputSelectionProps {
  label: string;
  type: string;
  name: string;
}

export const UserInputSelection = ({
  label,
  ...rest
}: UserInputSelectionProps) => {
  const [field] = useField(rest);

  const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 150,
    },
    selectEmpty: {
      marginTop: theme.spacing(0),
      minWidth: 120,
    },
    location: {
      display: "inline-block",
      minWidth: 150,
      position: "relative",
    },
    mainDiv: {
      display: "flex",
      justifyContent: "flex-start",
    },
  }));

  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <div className={classes.location}>
        <InputLabel {...field} id="select">
          {label}
          <span>
            <ErrorMessage name={field.name} />
          </span>
        </InputLabel>
        <Select
          className={classes.selectEmpty}
          label={label}
          {...field}
          {...rest}
        >
          {["Yes", "No"].map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </div>
    </FormControl>
  );
};
