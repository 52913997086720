import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from "@material-ui/core";
import { AccountCircle, SearchRounded, EditRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Title from "../CommonUtils/title";
import hive from "../CommonUtils/Images/logo.png";
import Tabs from "./tabs";
import FormPage from "./form";
import { FetchWithTimeout } from "../CommonUtils/fetchWithTimeout";
import { apiURL } from "../../APIurls";
import { IDefaultLocation } from "../CommonUtils/LocationRenderer/Location";
import { DefaultLocation } from "../../App";
import { Autocomplete } from "@material-ui/lab";
import { IDateRange } from "../CommonUtils/setDateRange";
import CustomizedSnackbar from "../CommonUtils/snackbar";
import { Console } from "console";
import { IRegion } from "./userInputSelection";
import { GetRegionResponse } from "../CommonUtils/LocationRenderer/locationModels";

interface SearchEmployeeProps {
  isSearchBtnClicked: boolean;
}

interface ConfirmationModalProp {
  onModalOpen: any;
  onModalClose: any;
  resetSelfDeclaration: any;
  resetManagerApproval: any;
  resetDeleteSchedule: any;
  selfDeclaration: boolean;
  managerApproval: boolean;
  deleteSchedule: boolean;
  modalOpen: boolean;
  reqId: string;
  selectedSchedule: any[];
  adhocReqId: string;
  getEmployeeDetails: any;
  employeeId: string;
  openSnackbar: any;
}

export interface OnboardingDetails {
  firstshotdate: string;

  hasComorbidity: string;

  isvaccinated: string;
  secondshotdate: string;
}

export interface SelfDeclarationDetails {
  reqId: string;
  cough: boolean;
  fever: boolean;
  exposedToInfectedPeople: boolean;
  exposedToInfectedRegion: boolean;
  transport: boolean;
  shortBreath: boolean;
  soreThroat: boolean;
  isSelfDeclarationDone: any;
  isSelfDeclarationApproved: any;
  hasHeadache: boolean;
  hasNasalCongestion: boolean;
  hasOtherCovidSymptoms: boolean;
}

const ConfirmationModal = (props: ConfirmationModalProp) => {
  const {
    onModalClose,
    modalOpen,
    resetSelfDeclaration,
    resetManagerApproval,
    resetDeleteSchedule,
    selfDeclaration,
    managerApproval,
    deleteSchedule,
    reqId,
    selectedSchedule,
    adhocReqId,
    getEmployeeDetails,
    employeeId,
    openSnackbar,
  } = props;

  let defaultLocation: IDefaultLocation = {
    regionId: "",
    locationId: "",
  };

  defaultLocation = useContext(DefaultLocation);

  const handleYesButton = async () => {
    if (selfDeclaration) {
      await FetchWithTimeout(apiURL.RESET_SELFDECLARATION, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${defaultLocation.accessToken}`,
        },
        body: JSON.stringify({
          reqid: reqId,
        }),
        mode: "cors",
      });

      // openSnackbar();
    }
    if (deleteSchedule) {
      if (selectedSchedule.includes(null)) {
        openSnackbar();
        return;
      }

      try {
        const response = await FetchWithTimeout(apiURL.DELETE_SCHEDULE, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${defaultLocation.accessToken}`,
          },
          body: JSON.stringify({ reqid: selectedSchedule }),
          mode: "cors",
        });
        const message = await response.json();

        if (message.message === "Error while updating the DB") {
          openSnackbar();
        }
      } catch (error: any) {
        console.log(error.message);
        if (error.message) {
          openSnackbar();
        }
      }
    }
    if (managerApproval) {
      await FetchWithTimeout(apiURL.SKIP_MANAGERAPPROVAL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${defaultLocation.accessToken}`,
        },
        body: JSON.stringify({ reqid: adhocReqId }),
        mode: "cors",
      });
    }

    const response = await FetchWithTimeout(apiURL.GET_EMPPROFILE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${defaultLocation.accessToken}`,
      },
      body: JSON.stringify({
        employeeId: employeeId,
      }),
      mode: "cors",
    });

    const data = await response.json();

    getEmployeeDetails(data.Result);
  };

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={() => {
          onModalClose();
          resetSelfDeclaration();
          resetManagerApproval();
          resetDeleteSchedule();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure? You want to
            {selfDeclaration && !managerApproval && !deleteSchedule
              ? " reset self declaration!"
              : !selfDeclaration && managerApproval && !deleteSchedule
              ? " skip manager approval!"
              : " delete schedule!"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onModalClose();
              resetSelfDeclaration();
              resetManagerApproval();
              resetDeleteSchedule();
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              onModalClose();
              handleYesButton();
              resetSelfDeclaration();
              resetManagerApproval();
              resetDeleteSchedule();
            }}
            autoFocus
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export interface Suggestions {
  empid: string;
  empfirstname: string;
  emplastname: string;
  emailid: string;
}

const SearchEmployee = ({ isSearchBtnClicked }: SearchEmployeeProps) => {
  let suggetionList: Suggestions[] = [];
  let inputVal: string = "";
  let emp: any = null;
  let isaliasfor: any = null;
  let scheduleReqId: string[] = [];
  let emptydateRange: IDateRange = {
    startDate: "",
    endDate: "",
  };
  let emptyRegionArray: IRegion[] = [];

  const [inputValue, setInputValue] = useState(inputVal);
  const [employee, setEmployee] = useState(emp);
  const [open, setOpen] = useState(false);
  const [selfDeclaration, setSelfDeclaration] = useState(false);
  const [managerApproval, setManagerApproval] = useState(false);
  const [deleteSchedule, setDeleteSchedule] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(suggetionList);
  const [isClicked, setIsClicked] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [selectedSchedule, setSelectedSchedule] = useState(scheduleReqId);
  const [adhocReqId, setAdhocReqId] = useState("");
  const [noData, setNoData] = useState(false);
  const [dateRange, setDateRange] = useState(emptydateRange);
  const [employeeId, setEmployeeId] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [regionItems, setRegionItems] = useState(emptyRegionArray);
  const [alias, setAlias] = useState(isaliasfor);
  const [resetSelfDeclarationReqId, setResetSelfDeclarationReqId] =
    useState("");

  const searchValue = useRef<TextFieldProps>(null);

  let defaultLocation: IDefaultLocation = {
    regionId: "",
    locationId: "",
  };

  defaultLocation = useContext(DefaultLocation);

  const useStyle = makeStyles((theme) => ({
    wrapper: {
      padding: theme.spacing(2),
    },

    search: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    textField: {
      width: "95%",
    },

    button: {
      margin: "0 10px",
      padding: "0.3rem 2rem",

      fontSize: "1.1rem",
    },

    data: {
      fontSize: "1rem",
      wordWrap: "break-word",
    },

    wrapper1: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: "2rem",

      display: "grid",
      gridTemplateColumns: "50% 50%",
    },

    sideheading: {
      fontSize: "0.9rem",

      color: "rgba(0, 0, 0, 0.54)",
    },

    container: {
      marginTop: "2.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    },

    container1: {
      display: "grid",
      placeItems: "center",

      padding: "1rem",
    },

    avatar: {
      height: "125px",
      width: "125px",
    },

    name: {
      fontWeight: 600,
    },

    container2: {
      borderRight: "solid rgba(0, 0, 0, 1)",

      marginRight: "3rem",
    },

    container3: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",

      width: "40%",
    },

    employeeDetailContainer: {
      display: "grid",
      gridTemplateColumns: "50% 50%",

      margin: "0.7rem 0",
    },

    employeeDetailContainerLeft: {
      display: "grid",
      gridTemplateColumns: "60% 40%",

      margin: "0.7rem 0",
    },

    tab: {
      marginTop: "2rem",
    },

    edit: {
      color: "#3f71c0",
      textDecoration: "underline",

      cursor: "pointer",
    },

    image: {
      width: "5rem",
      height: "5rem",

      borderRadius: "50%",
    },

    img: {
      width: "125px",
      height: "125px",

      marginRight: "1rem",

      borderRadius: "50%",
    },

    hive: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      margin: "1rem",
    },

    hiveText: {
      fontSize: "3.5rem",

      marginLeft: "0.2rem",
    },

    searchPageTextField: {
      width: "100%",
      margin: "1rem 0",
    },

    searchPageButton: {
      margin: "1rem",
    },
  }));

  const classes = useStyle();

  const onboardingDetails: OnboardingDetails = {
    firstshotdate: employee?.firstshotdate,
    hasComorbidity: employee?.hasComorbidity,
    isvaccinated: employee?.isvaccinated,
    secondshotdate: employee?.secondshotdate,
  };

  const selfDeclarationDetails: SelfDeclarationDetails = {
    reqId: employee?.todaysSelfdeclaration?.length
      ? employee?.todaysSelfdeclaration[0].reqid
      : "",
    cough: employee?.todaysSelfdeclaration?.length
      ? employee?.todaysSelfdeclaration[0].cough
      : "",
    fever: employee?.todaysSelfdeclaration?.length
      ? employee?.todaysSelfdeclaration[0].fever
      : "",
    shortBreath: employee?.todaysSelfdeclaration?.length
      ? employee?.todaysSelfdeclaration[0].shortbreath
      : "",
    soreThroat: employee?.todaysSelfdeclaration?.length
      ? employee?.todaysSelfdeclaration[0].sorethroat
      : "",
    exposedToInfectedPeople: employee?.todaysSelfdeclaration?.length
      ? employee?.todaysSelfdeclaration[0].exposedtoinfectedpeople
      : "",
    exposedToInfectedRegion: employee?.todaysSelfdeclaration?.length
      ? employee?.todaysSelfdeclaration[0].exposedtoinfectedregion
      : "",
    transport: employee?.todaysSelfdeclaration?.length
      ? employee?.todaysSelfdeclaration[0].transport
      : "",
    isSelfDeclarationDone: employee?.todaysSelfdeclaration?.length
      ? employee?.todaysSelfdeclaration[0].isselfdeclarationdone
      : "",
    isSelfDeclarationApproved: employee?.todaysSelfdeclaration?.length
      ? employee?.todaysSelfdeclaration[0].isselfdeclarationapproved
      : "",
    hasHeadache: employee?.todaysSelfdeclaration?.length
      ? employee?.todaysSelfdeclaration[0]?.hasHeadache
      : "",
    hasNasalCongestion: employee?.todaysSelfdeclaration?.length
      ? employee?.todaysSelfdeclaration[0]?.hasNasalCongestion
      : "",
    hasOtherCovidSymptoms: employee?.todaysSelfdeclaration?.length
      ? employee?.todaysSelfdeclaration[0]?.hasOtherCovidSymptoms
      : "",
  };

  const handleSkipManagerApprovalButton = () => {
    setManagerApproval(true);
    handleClickOpen();
  };

  const getSuggestionData = async (value: string) => {
    const response = await FetchWithTimeout(apiURL.GET_SUGGESTIONS, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${defaultLocation.accessToken}`,
      },
      body: JSON.stringify({
        search: value,
      }),
      mode: "cors",
    });

    const data = await response.json();

    return data;
  };

  const getProfilePic = async (value: string) => {
    try {
      const response = await FetchWithTimeout(
        `https://apactest.ingrammicro.com/HiveApi/profilepic/${value}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${defaultLocation.accessToken}`,
          },

          mode: "cors",
        }
      );

      const imgBlob = await response.blob();
      const imgUrl = URL.createObjectURL(imgBlob);

      if (imgBlob.size <= 27) {
        setProfilePic("");
      } else {
        setProfilePic(imgUrl);
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (inputValue && inputValue.length > 2) {
      getSuggestionData(inputValue).then((data) => {
        inputValue && data.Result
          ? setSuggestions(data.Result)
          : setSuggestions([]);
      });
    }
    if (!inputValue) setSuggestions([]);
  }, [inputValue]);

  // useEffect(() => {
  //   if (!suggestions.length) {
  //     setEmployeeId("");
  //   }
  // }, [suggestions]);

  const clearBtn = document.getElementsByClassName(
    "MuiAutocomplete-endAdornment"
  )[0];
  clearBtn?.addEventListener("click", () => {
    setInputValue("");
    setEmployeeId("");
    setNoData(false);
  });

  useEffect(() => {
    const clearBtn = document.getElementsByClassName(
      "MuiAutocomplete-endAdornment"
    )[0];
    clearBtn?.addEventListener("click", () => {
      setInputValue("");
      setEmployeeId("");
    });
  }, [isFormOpen]);

  useEffect(() => {
    const getRegion = async () => {
      try {
        const response = await FetchWithTimeout(apiURL.GET_REGION, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${defaultLocation.accessToken}`,
          },
          mode: "cors",
        });
        return response.json();
      } catch (error: any) {
        console.log(error.message);
      }
    };
    getRegion().then((data) => {
      if (data) {
        let result = GetRegionResponse.getParsedResult(data);
        if (result.status == "Success") {
          if (result.locations && result.locations.length > 0) {
            let region: IRegion[] = [];
            result.locations.forEach((p) => {
              region.push({
                label: p.name,
                value: p.regionid,
              });
            });

            setRegionItems(region);
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (employee?.isaliasfor) {
      getSuggestionData(employee?.isaliasfor).then((data) =>
        setAlias(data.Result)
      );
    } else {
      setAlias("");
    }
  }, [employeeId, employee?.isaliasfor, employee?.empid, employee]);

  useEffect(() => {
    setEmployee(null);
    setInputValue("");
    setEmployeeId("");
  }, [isSearchBtnClicked]);

  useEffect(() => {
    if (employee) {
      setSuggestions([]);
    } else if (!employee) {
      setIsFormOpen(false);
    }
  }, [employee]);

  const region = regionItems?.filter(
    (item) => item?.value === employee?.regionId
  )[0]?.label;

  const handleInputValueChange = (e: any) => {
    setInputValue(e.target.value);
    if (suggestions[0]?.empid.includes(e.target.value)) {
      setEmployeeId(e.target.value);
      setInputValue(e.target.value);
    }

    if (noData) {
      setNoData(false);
    }
  };

  const handleSearchButton = async () => {
    if (inputValue) {
      const response = await FetchWithTimeout(apiURL.GET_EMPPROFILE, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${defaultLocation.accessToken}`,
        },
        body: JSON.stringify({
          employeeId: employeeId,
        }),
        mode: "cors",
      });

      const data = await response.json();

      if (!data.Result) {
        setNoData(true);
      } else {
        setNoData(false);
      }
      setEmployee(data.Result);
      setIsClicked((prevState) => !prevState);

      getProfilePic(employeeId);
    } else {
      alert("Please provide valid Employee Id or Email!");
    }
  };

  const handleEnterButton = (e: any) => {
    if (e.key === "Enter") {
      handleSearchButton();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResetSelfDeclarationButton = (value: string) => {
    setResetSelfDeclarationReqId(value);
    setSelfDeclaration(true);
    handleClickOpen();
  };

  const handleDeleteScheduleButton = () => {
    setDeleteSchedule(true);
    handleClickOpen();
  };

  const resetSelfDeclaration = () => {
    setSelfDeclaration(false);
  };

  const resetManagerApproval = () => {
    setManagerApproval(false);
  };

  const resetDeleteSchedule = () => {
    setDeleteSchedule(false);
  };

  const handleVisibilityOfForm = () => {
    setIsFormOpen((prevValue) => !prevValue);
  };

  const handleInputValue = async (e: any) => {
    if (e) {
      if (e.key === "Enter") {
        const response = await FetchWithTimeout(apiURL.GET_EMPPROFILE, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${defaultLocation.accessToken}`,
          },
          body: JSON.stringify({
            employeeId: employeeId,
          }),
          mode: "cors",
        });

        const data = await response.json();

        if (!data.Result) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        setEmployee(data.Result);
        setIsClicked((prevState) => !prevState);

        getProfilePic(employeeId);

        const selected = suggestions.filter(
          (suggestion) => suggestion.empid === employeeId
        );
        if (selected[0].emailid.includes(inputValue)) {
          setInputValue(selected[0].emailid);
        } else {
          setInputValue(selected[0].empid);
        }
      }
      const value: string = e.target.innerText?.split(" ")[0];
      if (value) {
        setEmployeeId(value);
        const inputVal: string[] = e.target.innerText?.split("-");
        setNoData(false);
        inputVal.forEach((item) => {
          if (item.includes(inputValue)) {
            setInputValue(item.trim());
          }
        });
        if (value) {
          const response = await FetchWithTimeout(apiURL.GET_EMPPROFILE, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${defaultLocation.accessToken}`,
            },
            body: JSON.stringify({
              employeeId: value,
            }),
            mode: "cors",
          });

          const data = await response.json();

          if (!data.Result) {
            setNoData(true);
          } else {
            setNoData(false);
          }
          setEmployee(data.Result);
          setIsClicked((prevState) => !prevState);

          getProfilePic(value);
        }
      }
    }
  };

  const regExp = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$");

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
  };

  const openSnackbar = () => {
    setIsSnackbarOpen(true);
  };

  const getScheduleReqId = (value: string[]) => {
    setSelectedSchedule(value);
  };

  const getAdhocReqId = (value: string) => {
    setAdhocReqId(value);
  };

  const getEmployeeDetails = (value: any) => {
    setEmployee(value);
  };

  const getDateRange = (value: any) => {
    setDateRange(value);
  };

  const selectSuggestionsByEnterBtn = (e: any, option: any) => {
    if (option) {
      setEmployeeId(option.empid);
    }
  };

  // console.log(employee);

  return (
    <>
      {!isFormOpen && employee ? (
        <div>
          <ConfirmationModal
            onModalOpen={handleClickOpen}
            onModalClose={handleClose}
            resetSelfDeclaration={resetSelfDeclaration}
            resetManagerApproval={resetManagerApproval}
            resetDeleteSchedule={resetDeleteSchedule}
            selfDeclaration={selfDeclaration}
            managerApproval={managerApproval}
            deleteSchedule={deleteSchedule}
            modalOpen={open}
            reqId={resetSelfDeclarationReqId}
            selectedSchedule={selectedSchedule}
            adhocReqId={adhocReqId}
            getEmployeeDetails={getEmployeeDetails}
            employeeId={employee.employeeId}
            openSnackbar={openSnackbar}
          />
          <Title variant="h4">Search Employee</Title>

          <Paper className={classes.wrapper}>
            <div className={classes.search}>
              <Autocomplete
                inputValue={inputValue}
                onInputChange={handleInputValue}
                freeSolo
                onHighlightChange={selectSuggestionsByEnterBtn}
                noOptionsText="No match found!"
                size="small"
                options={suggestions}
                getOptionLabel={(option) =>
                  `${option.empid} - ${option.emailid}`
                }
                className={classes.searchPageTextField}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search Employee by Id or Email"
                    onChange={handleInputValueChange}
                    onKeyDown={handleEnterButton}
                  />
                )}
              />
              <Button
                variant="contained"
                size="small"
                color="primary"
                className={classes.button}
                onClick={handleSearchButton}
              >
                <SearchRounded />
                Search
              </Button>
            </div>

            {!employee ? (
              <div className={classes.container1}>No Data Found!</div>
            ) : (
              <>
                <Grid className={classes.wrapper1} container spacing={2}>
                  <div className={classes.container2}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      {!profilePic ? (
                        <AccountCircle
                          className={classes.avatar}
                          color="primary"
                          viewBox="2 0 24 24"
                        />
                      ) : (
                        <img
                          src={profilePic}
                          alt="Profile Image"
                          className={classes.img}
                        />
                      )}
                      <div>
                        <Typography className={classes.name} variant="h6">
                          {employee.firstName} {employee.lastName}
                        </Typography>
                        <a
                          className={classes.edit}
                          onClick={handleVisibilityOfForm}
                          style={{ textAlign: "center" }}
                        >
                          Edit
                          <EditRounded fontSize="inherit" viewBox="0 0 24 18" />
                        </a>
                      </div>
                    </div>
                    <div className={classes.employeeDetailContainerLeft}>
                      <div>
                        <Typography
                          className={classes.sideheading}
                          variant="h6"
                        >
                          Email
                        </Typography>
                        <Typography className={classes.data} variant="h6">
                          {employee.emailId ? employee.emailId : "NA"}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          className={classes.sideheading}
                          variant="h6"
                        >
                          Phone No.
                        </Typography>
                        <Typography className={classes.data} variant="h6">
                          {employee.contactNo ? employee.contactNo : "NA"}
                        </Typography>
                      </div>
                    </div>

                    <div className={classes.employeeDetailContainerLeft}>
                      <div>
                        <Typography
                          className={classes.sideheading}
                          variant="h6"
                        >
                          Employee Id
                        </Typography>
                        <Typography className={classes.data} variant="h6">
                          {employee.employeeId ? employee.employeeId : "NA"}
                        </Typography>
                      </div>

                      <div>
                        <Typography
                          className={classes.sideheading}
                          variant="h6"
                        >
                          Address
                        </Typography>
                        <Typography className={classes.data} variant="h6">
                          {employee.fullAddress ? employee.fullAddress : "NA"}
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <div className={classes.employeeDetailContainer}>
                        <div>
                          <Typography
                            className={classes.sideheading}
                            variant="h6"
                          >
                            Manager Name
                          </Typography>
                          <Typography className={classes.data} variant="h6">
                            {employee.manager ? employee.manager : "NA"}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            className={classes.sideheading}
                            variant="h6"
                          >
                            Employment Type
                          </Typography>
                          <Typography className={classes.data} variant="h6">
                            {regExp.test(employee?.employeeId)
                              ? "Contractor"
                              : "Full time"}
                          </Typography>
                        </div>
                      </div>

                      <div className={classes.employeeDetailContainer}>
                        <div>
                          <Typography
                            className={classes.sideheading}
                            variant="h6"
                          >
                            Designation
                          </Typography>
                          <Typography className={classes.data} variant="h6">
                            {employee.title}
                          </Typography>
                        </div>

                        <div>
                          <Typography
                            className={classes.sideheading}
                            variant="h6"
                          >
                            Department
                          </Typography>
                          <Typography className={classes.data} variant="h6">
                            {employee.department ? employee.department : "NA"}
                          </Typography>
                        </div>
                      </div>

                      <div className={classes.employeeDetailContainer}>
                        <div>
                          <Typography
                            className={classes.sideheading}
                            variant="h6"
                          >
                            Location
                          </Typography>
                          <Typography className={classes.data} variant="h6">
                            {employee?.roleName ? employee?.roleName[0] : ""}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            className={classes.sideheading}
                            variant="h6"
                          >
                            Location Id
                          </Typography>
                          <Typography className={classes.data} variant="h6">
                            {employee.location ? employee.location : "NA"}
                          </Typography>
                        </div>
                      </div>

                      <div className={classes.employeeDetailContainer}>
                        <div>
                          <Typography
                            className={classes.sideheading}
                            variant="h6"
                          >
                            City
                          </Typography>
                          <Typography className={classes.data} variant="h6">
                            {employee.city ? employee.city : "NA"}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            className={classes.sideheading}
                            variant="h6"
                          >
                            Has Left
                          </Typography>
                          <Typography className={classes.data} variant="h6">
                            {!employee["hasleft:"] ? "No" : "Yes"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <div className={classes.tab}>
                  <Tabs
                    onHandleResetSelfDeclaration={
                      handleResetSelfDeclarationButton
                    }
                    onHandleSkipManagerApproval={
                      handleSkipManagerApprovalButton
                    }
                    onHandleDeleteSchedule={handleDeleteScheduleButton}
                    onboardingDetails={onboardingDetails}
                    employeeId={employee.employeeId}
                    isClicked={isClicked}
                    getScheduleReqId={getScheduleReqId}
                    getAdhocReqId={getAdhocReqId}
                    getDateRange={getDateRange}
                    getEmployeeDetails={getEmployeeDetails}
                    todaysVisit={employee?.todaysSelfdeclaration?.length}
                    deleteSchedule={deleteSchedule}
                    managerApproval={managerApproval}
                    resetSelfDeclaration={selfDeclaration}
                  />
                </div>
              </>
            )}
          </Paper>
        </div>
      ) : isFormOpen && employee ? (
        <div>
          <FormPage
            onHandleSubmitButton={handleVisibilityOfForm}
            employee={employee}
            region={region}
            getEmployeeDetails={getEmployeeDetails}
            alias={alias ? alias : ""}
          />
        </div>
      ) : (
        <div className={classes.container3}>
          <div className={classes.hive}>
            <img src={hive} alt="HIVE" className={classes.image} />
            <Typography
              className={classes.hiveText}
              variant="h5"
              color="primary"
            >
              HIVE
            </Typography>
          </div>
          <div className={classes.searchPageTextField}>
            <Autocomplete
              inputValue={inputValue}
              freeSolo
              onInputChange={handleInputValue}
              noOptionsText="No match found!"
              size="small"
              onHighlightChange={selectSuggestionsByEnterBtn}
              options={suggestions}
              getOptionLabel={(option) => `${option.empid} - ${option.emailid}`}
              className={classes.searchPageTextField}
              renderOption={(option) => {
                return <li>{`${option.empid} - ${option.emailid}`}</li>;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search Employee by Id or Email"
                  onChange={handleInputValueChange}
                />
              )}
              // open={inputValue.length > 2}
            />
          </div>
          {!employee && noData ? (
            <Typography
              style={{ marginBottom: "0.8rem" }}
              variant="h4"
              align="center"
            >
              No Data Found!
            </Typography>
          ) : (
            <></>
          )}
          <div className={classes.searchPageButton}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.button}
              onClick={handleSearchButton}
            >
              <SearchRounded />
              Search
            </Button>
          </div>
        </div>
      )}
      <CustomizedSnackbar
        status={"error"}
        message={"Today's schedule can't be deleted!"}
        displaySnackbar={isSnackbarOpen}
        handleClose={handleSnackbarClose}
      />
    </>
  );
};

export default SearchEmployee;
