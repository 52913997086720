import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Location from '../CommonUtils/LocationRenderer/Location';
import Title from '../CommonUtils/title';
import { FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Tab, Tabs } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Send, FormatClear, CloudDownload } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CustomizedSnackbar from '../CommonUtils/snackbar';
import SetDateRange, { IDateRange } from '../CommonUtils/setDateRange';
import { apiURL } from '../../APIurls';
import { FetchWithTimeout } from '../CommonUtils/fetchWithTimeout';
import { DefaultLocation } from '../../App';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: "100%",
    },
    downloadButton: {
        width: 'fit-content',
        margin: theme.spacing(2)
    },
    reportType: {
        margin: theme.spacing(2),
    },
    location: {
        margin: theme.spacing(1),
    },
    locationTitle: {
        marginLeft: theme.spacing(1),
    },


}));

interface IResult {
    status: string,
    message: string
}
interface IReportType {
    reportName: string,

}
const DownloadReport = () => {

    const classes = useStyles();
    let emptyResult: IResult = {
        status: "",
        message: "",
    };
    let emptydateRange: IDateRange = {
        startDate: "",
        endDate: ""
    }
    const [result, setResult] = React.useState(emptyResult);
    const [dateRange, setDateRange] = React.useState(emptydateRange);
    const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);
    const [locationid, setLocationid] = React.useState('');
    const [regionid, setRegionid] = React.useState('');
    const [reportType, setReportType] = React.useState('');
    const defaultLocation = useContext(DefaultLocation);

    const setRegionidFromDropDown = (id: string) => {
        setRegionid(id);
        setLocationid('');
    }
    const setLocationidFromDropDown = (id: string) => {
        setLocationid(id);
    }
    const SetDateRangeFun = (dateRange: IDateRange) => {
        setDateRange(dateRange);
    }
    const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }

    const handleReportTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setReportType(event.target.value as string);
    }
    const downloadReport = async () => {
        let url = '';
        let reqBody = {};
        let fileName = '';
        let reportLocation = '';
        let reportRegion = '';
        regionid == "0" ? reportRegion = '' : reportRegion = regionid;
        locationid == "0" ? reportLocation = '' : reportLocation = locationid;

        regionid == "0" ? fileName = 'All'
            : locationid == "0" || locationid == '' ? fileName = regionid
                : fileName = locationid;
        switch (reportType) {
            case 'CovidPositive':
                //url = 'https://ingramrtw.azurewebsites.net/getcovidpositive';
                //url = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/getcovidpositive';
                url = apiURL.Get_COVIDPOSITIVE_REPORT;
                reqBody = {
                    "locationid": reportLocation,
                    "regionid": reportRegion,
                    "download": "true",
                    "startdateofdetection": dateRange.startDate,
                    "enddateofdetection": dateRange.endDate
                };
                fileName = "" + 'CovidPositive_' + fileName;
                break;
            case 'attendancereport':
                //url = 'https://ingramrtw.azurewebsites.net/attendancereport';
                //url = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/attendancereport';
                url = apiURL.Get_ATTENDANCE_REPORT;
                reqBody = {
                    "startdate": dateRange.startDate,
                    "enddate": dateRange.endDate,
                    "locationid": reportLocation,
                    "regionid": reportRegion,
                };
                fileName = "" + 'AttendanceReport_' + fileName;
                break;
            case 'accessdeniedreport':
                //url = 'https://ingramrtw.azurewebsites.net/accessdeniedreport';
                //url = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/accessdeniedreport';
                url = apiURL.Get_ACCESSDENIED_REPORT;
                reqBody = {
                    "startdate": dateRange.startDate,
                    "enddate": dateRange.endDate,
                    "locationid": reportLocation,
                    "regionid": reportRegion,
                };
                fileName = "" + 'AccessDeniedReport_' + fileName;
                break;
            case 'vaccinationreport':
                //url = 'https://ingramrtw.azurewebsites.net/accessdeniedreport';
                //url = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/accessdeniedreport';
                url = apiURL.Get_VACCINATION_REPORT;
                reqBody = {                    
                    "locationid": reportLocation,
                    "regionid": reportRegion,
                };
                fileName = "" + 'VaccinationReport' + fileName;
                break;

        }
        try {
            await FetchWithTimeout(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${defaultLocation.accessToken}`,
                },
                body: JSON.stringify(reqBody),
                mode: 'cors',
                //timeout: 8000
            }).then((response) => {

                console.log("response", response);

                if (response.status === 200) {
                    response.blob().then(blob => download(blob, fileName));
                } else if (response.status === 500) {
                    setResult({
                        status: "Failed",
                        message: "No Data Found."
                    });
                    setSnackbarOpen(true);
                } else if (response.status === 400) {
                    setResult({
                        status: "Failed",
                        message: "Oops!! Something went wrong."
                    });
                    setSnackbarOpen(true);
                } else {
                    setResult({
                        status: "Failed",
                        message: "Oops!! Something went wrong."
                    });
                    setSnackbarOpen(true);
                }
            })
        } catch (error) {
            // Timeouts if the request takes
            // longer than 6 seconds
            error.name === 'AbortError' ?
                setResult({
                    status: "Failed",
                    message: "Your request has timed out. Try again in sometime."
                })
                : setResult({
                    status: "Failed",
                    message: "Oops!! Something went wrong."
                });
            setSnackbarOpen(true);

        }

        /* fetch(url, {
             method: 'POST',
             headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json',
                 "Access-Control-Allow-Origin": "*",   "Authorization": `Bearer ${ defaultLocation.accessToken }` ,
             },
             body: JSON.stringify(reqBody),
             mode: 'cors',
         })
             .then((response) => {
 
                 console.log("response", response);
 
                 if (response.status === 200) {
                     response.blob().then(blob => download(blob, fileName));
                 } else if (response.status === 500) {
                     setResult({
                         status: "Failed",
                         message: "No Data Found."
                     });
                     setSnackbarOpen(true);
                 } else if (response.status === 400) {
                     setResult({
                         status: "Failed",
                         message: "Oops!! Something went wrong."
                     });
                     setSnackbarOpen(true);
                 } else {
                     setResult({
                         status: "Failed",
                         message: "Oops!! Something went wrong."
                     });
                     setSnackbarOpen(true);
                 }
             })
             .catch((error) => {
                 console.error('Error:', error);
             }); */
    }



    function download(blob: Blob, filename?: string) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want

        a.download = filename ? filename : "noname";
        a.download = a.download + ".csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Title variant="h4">
                    Download Report
                </Title>
                <Paper className={clsx(classes.paper, classes.fixedHeight)}>
                    <div className={classes.reportType}>
                        <Title variant="subtitle1">Report type</Title>
                        <Select
                            labelId="Select-Batch"
                            id="selectBatch"
                            value={reportType ? reportType : "0"}
                            onChange={handleReportTypeChange}
                        >
                            <MenuItem key="0" value="0" > Select Report Type </MenuItem>
                            <MenuItem key="CovidPositive" value="CovidPositive">Covid positive report</MenuItem>
                            <MenuItem key="attendancereport" value="attendancereport">Attendance report</MenuItem>
                            <MenuItem key="accessdeniedreport" value="accessdeniedreport">Access Denied report</MenuItem>
                            <MenuItem key="vaccinationreport" value="vaccinationreport">Vaccination report</MenuItem>

                        </Select>
                    </div>
                    <div className={classes.location}>
                        <div className={classes.locationTitle} >
                            <Title variant="subtitle1">Location</Title>
                        </div>
                        <Location setLocationid={setLocationidFromDropDown} setRegionid={setRegionidFromDropDown} setDefaultvalues="none" />
                    </div>
                    {reportType != "vaccinationreport" &&
                        <div className={classes.reportType} >
                            <Title variant="subtitle1">{reportType === "CovidPositive" ? "Select Detection Date Range" :"Select Date Range"}</Title>
                            <SetDateRange setDateRange={SetDateRangeFun} />
                        </div>
                    }

                    <Tooltip
                        title={(reportType === '') || (reportType === "0") ? "Please select Report Type"
                            : (locationid == '' && regionid == '') ? "Please select Location/Region"
                                : ((dateRange.startDate == "") || (dateRange.endDate == "") || (reportType != "vaccinationreport") ) ? "Please select Date Range"
                                    : "Click to download selected Reoprt"}
                        arrow >
                        <div className={classes.downloadButton} >
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={(reportType === '') || (reportType === "0") || (locationid == '' && regionid == '') ||( reportType != "vaccinationreport" &&( (dateRange.startDate == "") || (dateRange.endDate == "")))}
                                onClick={() => downloadReport()}
                                startIcon={<CloudDownload />}
                            >
                                Download
                            </Button>
                        </div>
                    </Tooltip>
                </Paper>
                <CustomizedSnackbar status={result.status} message={result.message} displaySnackbar={isSnackbarOpen} handleClose={handleSnackbarClose} />
            </Grid>
        </Grid>
    );
}

export default DownloadReport;