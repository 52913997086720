import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

interface IGraphData {
    weekid: string;
    monthid: number;
    dayid: string;
    Planned: number;
    Actuals: number;
    Adhoc: number;
}
interface chartProps {
    data: IGraphData[],
    type: string,
}
export default function Chart(props: chartProps) {
    const data = props.data;
    return (
        <ResponsiveContainer width="90%" height="100%">
            <BarChart
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
            barCategoryGap={"10%"}
            barGap={"0%"}
            //barSize="10%"
           // maxBarSize={50}
            width={1000}
            height={ 500}
            >
                <CartesianGrid strokeDasharray="5 5" />
                <XAxis
                    dataKey={(props.type === "1") ? "dayid" : "weekid"}
                    label={{ value: (props.type === "1") ? "Dates Of the week" : "Weeks of the month", position: 'insideBottom', textAnchor: 'middle', offset: -5 }}
                />
                <YAxis label={{ value: 'No. of seats/associates', angle: -90, position: 'insideLeft', offset: 20 }} allowDecimals={false} />
                <Tooltip />
                <Legend verticalAlign="top" height={10} align="right" wrapperStyle={{ paddingBottom: "30px" }} />
                <Bar dataKey="Planned" fill="#4574c6" isAnimationActive={false} >
                    <LabelList dataKey="Planned" position="top" />
                </Bar>
                <Bar dataKey="Actuals" fill="#11cb5f" isAnimationActive={false} >
                    <LabelList dataKey="Actuals" position="top" />
                </Bar>
                <Bar dataKey="Adhoc" fill="#ef7e32" isAnimationActive={false} >
                    <LabelList dataKey="Adhoc" position="top" />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );

}
//#8884d8