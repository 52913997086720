import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { MenuItem, Tooltip }from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {Select} from '@material-ui/core';
import { GetLocationResponse, GetRegionResponse } from './locationModels';
import { DefaultLocation } from '../../../App';
import { apiURL } from '../../../APIurls';
import CustomizedSnackbar from '../snackbar';
import { FetchWithTimeout } from '../fetchWithTimeout';

interface locationProps {
    regionid?: string;
    items?:string;
    setLocationid: (id: string) => void;
    setRegionid: (id: string) => void;
    setDefaultvalues?: "none" | string;
}


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150
    },
    selectEmpty: {
        marginTop: theme.spacing(0),
        minWidth:120
    },
    location:{
        display:'inline-block',
        minWidth:150,
        position:'relative' 
    },
    mainDiv: {
        display: 'flex',
        justifyContent:'flex-start'
       
    }
}));
export interface IDefaultLocation {
    regionId: string;
    locationId: string;
    accessToken?: string;
}
interface IRegion {
    value: string,
    label: string
}

interface ILocation {
    value: string,
    label: string
}

interface IResult {
    status: string,
    message: string
}
export default function SelectLocation(props: locationProps) {
    let emptyRegionArray: IRegion[] = [];
    let emptyLocationArray: ILocation[] = [];
    let emptyResult: IResult = {
        status: "",
        message: "",
    };
    let defaultLocation: IDefaultLocation = {
        regionId: '',
        locationId: ''
    };
    defaultLocation = useContext(DefaultLocation);
    const classes = useStyles();

    const [location, setLocation] = React.useState('');
    const [region, setRegion] = React.useState('');
    
    const [locationItems, setLocationItems] = React.useState(emptyLocationArray);
    const [regionItems, setRegionItems] = React.useState(emptyRegionArray);

    const [result, setResult] = React.useState(emptyResult);
    const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);

    const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }

    const locationHandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setLocation(event.target.value as string);
        props.setLocationid(event.target.value as string);
       
    };
    const regionHandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRegion(event.target.value as string);
        props.setRegionid(event.target.value as string);
        
    };    

    React.useEffect(() => {
        const getRegion = async () => {
            //const url = 'https://ingramrtw.azurewebsites.net/getregion';
            //const url = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/getregion';
            try {
                //const response = await FetchWithTimeout(`${process.env.REACT_APP_APIURL}/getregion`, {
                const response = await FetchWithTimeout(apiURL.GET_REGION, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${defaultLocation.accessToken}`,
                       // "Authorization": `Bearer ${ defaultLocation.accessToken }` ,
                    },
                    mode: 'cors',
                })
                return response.json();
            } catch (error) {
                // Timeouts if the request takes
                // longer than 6 seconds
                error.name === 'AbortError' ?
                    setResult({
                        status: "Failed",
                        message: "Your request has timed out. Try again in sometime."
                    })
                    : setResult({
                        status: "Failed",
                        message: "Oops!! Something went wrong."
                    });
                setSnackbarOpen(true);
            }
            
        }
        getRegion().then(data => {
            if (data) {
                let result = GetRegionResponse.getParsedResult(data);
                if (result.status == "Success") {
                    if (result.locations && result.locations.length > 0) {
                        let region: IRegion[] = [];
                        result.locations.forEach(p => {
                            region.push({
                                label: p.name,
                                value: p.regionid
                            });

                        });
                        setRegionItems(region);
                    }
                }
            }
        })
       
    }, []);

    React.useEffect(() => {
        if (props.setDefaultvalues !== "none") {
            let i = 0;
            for (i = 0; i < regionItems.length; i++) {
                if (regionItems[i].value === defaultLocation.regionId) {
                    setRegion(regionItems[i].value);
                    props.setRegionid(regionItems[i].value);
                }
            }
        } else {
            setRegion('');
            props.setRegionid('');
        }
    }, [regionItems])

    React.useEffect(() => {
        if (props.setDefaultvalues !== "none") {
        let i = 0;
        for (i = 0; i < locationItems.length; i++) {
            if (locationItems[i].value === defaultLocation.locationId) {
                setLocation(locationItems[i].value);
                props.setLocationid(locationItems[i].value);
            }
            }
        } else {
            setLocation('');
            props.setLocationid('');
        }
    }, [locationItems])

    React.useEffect(() => {
        if ((region !== "") && (region !== "0") ) {            
            const getLocation = async () => {
                const reqBody = {
                    regionid: region
                };
                var data = new FormData();
                data.append("json", JSON.stringify(reqBody));
                //const url = 'https://ingramrtw.azurewebsites.net/getlocation';
               // const url = 'https://sgdcezwweb3001.ecommerce.ingram/HiveAPI/getlocation';
                try {
                const response = await FetchWithTimeout(apiURL.GET_LOCATION, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${defaultLocation.accessToken}`, 
                    },
                    body: JSON.stringify(reqBody),
                    mode: 'cors',
                })
                    return response.json();
                } catch (error) {
                    // Timeouts if the request takes
                    // longer than 6 seconds
                    error.name === 'AbortError' ?
                        setResult({
                            status: "Failed",
                            message: "Your request has timed out. Try again in sometime."
                        })
                        : setResult({
                            status: "Failed",
                            message: "Oops!! Something went wrong."
                        });
                    setSnackbarOpen(true);
                }
            }
            getLocation().then(data => {
                if (data) {
                    let result = GetLocationResponse.getParsedResult(data);
                    if (result.status == "Success") {
                        if (result.locations && result.locations.length > 0) {
                            let location: ILocation[] = [];
                            result.locations.forEach(p => {
                                location.push({
                                    label: p.name,
                                    value: p.id
                                });

                            });
                            setLocationItems(location);
                        }
                    }
                }

            })
        }
        
    },[region])

    return (
        <>
            <div className={classes.mainDiv}>
                <FormControl className={classes.formControl} >
                    <div className = {classes.location}>
                        <InputLabel id="Select-Region">Region</InputLabel>
                        <Select
                            labelId="Select-Region"
                            id="selectRegion"
                            value={region?region:''}
                            onChange={regionHandleChange}
                            className={classes.selectEmpty}
                        >
                            {(props.setDefaultvalues === "none") && <MenuItem key="0" value="0" > All Region </MenuItem> }
                            {(regionItems).map(item => (
                                <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>                                     
                            ))}                    
                        </Select>

                </div>
            </FormControl>
                <FormControl className={classes.formControl} disabled={!((region !== "") && (region !== "0"))}>
                <div className={classes.location}>
                        <InputLabel id="Select-Location">Location</InputLabel>
                            <Tooltip title={!region ? "Please select region" : "select location"} arrow placement="top">
                                <Select
                                    labelId="Select-Location"
                                    id="selectLocation"
                                    value={location ? location :''}
                                    onChange={locationHandleChange}
                                    className={classes.selectEmpty}
                            >
                                {(props.setDefaultvalues === "none") && <MenuItem key="0" value="0" > All Location </MenuItem> }
                                    {(locationItems).map(item => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>  
                                    ))}                    
                            </Select>
                        </ Tooltip>
                </div>
                </FormControl>
                <br />
                <CustomizedSnackbar status={result.status} message={result.message} displaySnackbar={isSnackbarOpen} handleClose={handleSnackbarClose} />

            </div>
        </>
    );
}
