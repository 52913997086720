export class GetResultResponse {
    public static getParsedResult(result: any): GetResultServiceReponse {
        let parsedResult: GetResultServiceReponse = (result) as GetResultServiceReponse;
        return parsedResult;
    }
}

export class GetResultServiceReponse {
    constructor(data: any) {
        this.status = data.status;
        this.message = data.locations;
    }
    status: string;
    message: string;
}