import React, { useCallback, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ReactDropzone, { useDropzone } from 'react-dropzone';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Title from '../title';
import { Button, Tooltip } from '@material-ui/core';
import { CloudUpload, CloudDownload, NotificationsActive } from '@material-ui/icons';
import Location from '../LocationRenderer/Location';
import CustomizedSnackbar from '../snackbar';
import { GetResultResponse } from '../resultModels';
import { apiURL, pageType } from '../../../APIurls';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FetchWithTimeout } from '../fetchWithTimeout';
import { DefaultLocation } from '../../../App';


interface commonProps {
    title: string,
    subtitle: string,
    hasNotifyButton: boolean,
    type: pageType
}

interface IResult {
    status: string,
    message: string
}

interface IDownloadProps {
    name: string,
    filetype: string
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 440,
    },

    FixedWidth: {
        maxWidth: '150px',
    },
    downloadButton: {
        variant: "contained",
        color: 'primary',
        alignContent: 'Left',
    },
    uploadbutton: {
        margin: theme.spacing(0),
        padding: '',
    },
    flexRow: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    dropzoneStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '190px',
        border: '2px dotted black',
        textAlign: 'center'
    },
    location: {
        display: 'inline-block',
    },
    downloadbutton: {
        marginTop: theme.spacing(2),
        alignSelf: 'flex-end'
    },
    locationArea: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    downloadbuttondiv: {
        marginLeft: 2,
    },
    uploadbuttondiv: {
        marginRight: '20px'
    },
    UploadDivArea: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    pMargin: {
        margin: 0
    }
}));

const CommonPage = (props: commonProps) => {
    let emptyFile: File = new File([""], "filename");
    let emptyResult: IResult = {
        status: "",
        message: "",
    };
    let emptyDownloadData: IDownloadProps = {
        name: "",
        filetype: ""
    }

    const [uploadFile, setUploadFile] = React.useState(emptyFile);
    const [locationid, setLocationid] = React.useState('');
    const [regionid, setRegionid] = React.useState('');
    const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);
    const [result, setResult] = React.useState(emptyResult);
    const [uploadUrl, setUploadUrl] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [downloadParams, setDownloadParams] = React.useState(emptyDownloadData);
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    useEffect(() => {
        switch (props.type) {
            case pageType.EMPLOYEE_SCHEDULE:
                setUploadUrl(apiURL.EMPLOYEE_SCHEDULE);
                let empSchParams: IDownloadProps = {
                    name: "EmployeeShift",
                    filetype: pageType.EMPLOYEE_SCHEDULE
                }
                setDownloadParams(empSchParams)
                break;
            case pageType.ONBOARDING:
                setUploadUrl(apiURL.ONBOARDING);
                let onboardingParams: IDownloadProps = {
                    name: "Onboarding",
                    filetype: pageType.ONBOARDING
                }
                setDownloadParams(onboardingParams)
                break;
            case pageType.SEATING_DEATLS:
                setUploadUrl(apiURL.SEATING_DETAILS);
                let seatingDetailsParams: IDownloadProps = {
                    name: "SeatingData",
                    filetype: pageType.SEATING_DEATLS
                }
                setDownloadParams(seatingDetailsParams)
                break;
        }
    }, [])

    const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    }


    const { acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
        maxFiles: 1,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet ,application/vnd.ms-excel.sheet.macroenabled.12,.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values'
    })
    useEffect(() => {
        if (acceptedFiles[0] !== undefined) {
            setUploadFile(acceptedFiles[0]);
        }
    }, [acceptedFiles])

    const Loading = () => {
        setLoading(true);
    }
    const defaultLocation = useContext(DefaultLocation);
    const upload = (notifyusers?: boolean) => {
        const formData = new FormData();
        formData.append('file', uploadFile);
        formData.append('locationid', locationid);
        if (notifyusers) {
            formData.append('tonotify', '1');
        }
        const UploadFiledata = async () => {
            try {
                const response = await FetchWithTimeout(uploadUrl, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Accept': 'application/json',
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Bearer ${defaultLocation.accessToken}`, 
                    },
                    mode: 'cors',
                })
                return response.json();
            } catch (error) {
               return  error.name === 'AbortError' ?
                    {
                        status: "Failed",
                        message: "Your request has timed out. Try again in sometime."
                    }
                    :{
                        status: "Failed",
                        message: "Oops!! Something went wrong."
                    };
               // setSnackbarOpen(true);
               // setLoading(false);
            }
        }
        UploadFiledata().then(data => {
            if (data != undefined) {
                let res = GetResultResponse.getParsedResult(data);
                setResult(res);
                setLoading(false);
                setSnackbarOpen(true);
                if (res.status == "Success") {
                    setUploadFile(emptyFile);
                }
            }
        })
    }

    const setRegionidFromDropDown = (id: string) => {
        setRegionid(id);
        setLocationid('');
    }
    const setLocationidFromDropDown = (id: string) => {
        setLocationid(id);
    }

    const downloadtemplate = async () => {
        const reqBody = {
            name: locationid + "_" + downloadParams.name,
            filetype: downloadParams.filetype,
            location: locationid
        };
        try {
            await FetchWithTimeout(apiURL.DOWNLOAD_TEMPLATE, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${defaultLocation.accessToken}`, 
                },
                body: JSON.stringify(reqBody),
                mode: 'cors',
            }).then((response) => {
                    if (response.status === 200) {
                        response.blob().then(blob => download(blob, reqBody.name));
                    } else {
                        setResult({
                            status: "Failed",
                            message: "Oops!! Something went wrong."
                        });
                        setSnackbarOpen(true);
                    }
                    setLoading(false);
                })
        }
        catch (error) {
            error.name === 'AbortError' ?
                setResult({
                    status: "Failed",
                    message: "Your request has timed out. Try again in sometime."
                })
                : setResult({
                    status: "Failed",
                    message: "Oops!! Something went wrong."
                });
            setSnackbarOpen(true);
        }
    }

    function download(blob: Blob, filename?: string) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want

        a.download = filename ? filename : "noname";
        if (downloadParams.name == "Onboarding") { a.download = a.download + ".csv"; }
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }
    console.log("location", locationid);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Title variant="h4">
                    {props.title}
                </Title>
                <Paper className={fixedHeightPaper}>
                    <Title variant="subtitle1">
                        Get started by <b>selecting a location</b>
                    </Title>
                    <div className={classes.locationArea}>
                        <Location setLocationid={setLocationidFromDropDown} setRegionid={setRegionidFromDropDown} />
                        <div className={classes.downloadbuttondiv}>
                            <Tooltip title={locationid == "" ? "Please select a region and location" : "Click to download sample template"} arrow>
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={(locationid == "")}
                                        className={classes.downloadbutton}
                                        onClick={() => downloadtemplate()}
                                        startIcon={<CloudDownload />}
                                    >
                                        Download template
                                </Button>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <br /><br />
                    <Title variant="subtitle1">
                        Upload File with <b>{props.subtitle}</b>
                    </Title>
                    <div className={classes.dropzoneStyle} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div >
                            {
                                loading ? (<><CircularProgress /> <br /> <span>Uploading data...</span></>)
                                    : (
                                        <>
                                            <CloudUpload style={{ fontSize: 60 }} color="primary" />
                                            {
                                                (uploadFile !== emptyFile && uploadFile.name && uploadFile.name !== "filename") ?
                                                    <p className={classes.pMargin}>{uploadFile.name} - {uploadFile.size} bytes </p>
                                                    : (
                                                        isDragActive ?
                                                            <p>Drop the files here ...</p> :
                                                            <p className={classes.pMargin}>Drag 'n' drop a file here, or click to select a file</p>)}
                                        </>)

                            }
                        </div>

                    </div>
                    <br />
                    <br />
                    <div className={classes.UploadDivArea}>
                        <div className={classes.uploadbuttondiv}>
                            <Tooltip title={locationid == "" ? "Please select a region and location" : (uploadFile.size <= 0 ? "Please select a file" : "Click to upload the file")} arrow>
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={((locationid == "" || uploadFile.size <= 0) || loading)}
                                        className={classes.uploadbutton}
                                        startIcon={<CloudUpload />}
                                        onClick={() => { Loading(); upload() }}>
                                        Upload
                        </Button>
                                </div>
                            </Tooltip>
                        </div>
                        {props.hasNotifyButton &&
                            <div>
                                <Tooltip title={locationid == "" ? "Please select a region and location" : (uploadFile.size <= 0 ? "Please select a file" : "Click to upload the file and notify")} arrow>
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={((locationid == "" || uploadFile.size <= 0) || loading)}
                                            className={classes.uploadbutton}
                                            startIcon={<NotificationsActive />}
                                            onClick={() => { Loading(); upload(true) }}>
                                            Upload & notify
                                    </Button>
                                    </div>
                                </Tooltip>
                            </div>}
                    </div>
                </Paper>
                <CustomizedSnackbar status={result.status} message={result.message} displaySnackbar={isSnackbarOpen} handleClose={handleSnackbarClose} />
            </Grid>
        </Grid>
    );
}

export default CommonPage;